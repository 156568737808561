import { Divider } from "primereact/divider";
import { isHTTPS } from "utils/regexp";

export const toFioTemplate = (user: any) => (
  <div className="p-d-flex p-ai-center">
    <img 
      alt={user.fio} 
      src={`${isHTTPS(user.avatar) 
        ? user.avatar 
        : user.avatar 
          ? '/api/files/' + user.avatar
          : '/api/admin/files/default'
        }`}
      width={32}
      height={32}
      style={{ verticalAlign: 'middle', borderRadius: '50%', objectFit: 'cover' }} 
      className="p-mr-2"
    />
    
    <div className="p-d-flex p-flex-column">
      <span > <strong>{user.fio !== " " ? user.fio : 'Не указано'}</strong> (<i>tel:{user.phone}</i>)</span>
      {
        user?.owner && user?.owner?.owner 
          ? (
            <>

              <div style={{border: '1px solid'}}></div>

              <span > 
                <strong>
                  Владелец - {user.owner.owner.name} {user.owner.owner.surname ? user.owner.owner.surname : ""}
                </strong> 
                (<i>tel:{user.owner.owner.phone}</i>)
              </span>
            </>
          ) 
          : <></>
      }
      {user?.comment?.length ? (<span> "<i>{user.comment}</i>"</span>) : <></>} 
      
    </div>
  </div>
);