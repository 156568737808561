import { combineReducers } from 'redux'
import * as constants from "domain/constants/secretShopper.constant";
import * as types from "domain/types/secretShopper.type";

const defaultState = {
  data:[],
  current: null,
  receipt: null,
  requestsRemuneration: [],
  request: {
    loading: false,
    error: null
  }
}

const initialStateWindow = {
  bids: {
    views: false,
    createTasks: false,
    createBid: false
  },
  raffles: {
    views: false,
  },
  tasks: {
    views: false,
    receipt: false,
  },
  receipts: {
    views: false,
  },
  moderation: {
    views: false,
  },
  questions: {
    views: false,
    createQuestion: false,
    filters: false
  },
  schemas: {
    views: false,
    schemaQuestionnaire: false,
  }
};

const initialState = {
  bids: {...defaultState, windows:initialStateWindow.bids },
  raffles: {...defaultState, windows:initialStateWindow.raffles},
  tasks: {...defaultState, windows:initialStateWindow.tasks },
  receipts: {...defaultState, windows:initialStateWindow.receipts },
  moderation: {...defaultState, windows:initialStateWindow.moderation },
  questions: {...defaultState, windows:initialStateWindow.questions, filters: {
    category: [],
    subcategory: []
  }, },
  schemas: {...defaultState, windows:initialStateWindow.schemas },
  category: []
};


export function category(state = initialState.category, action: types.ShopperReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_CATEGORY:
      return action.payload.categories;
    default:
      return state;
  }
}

export function bids(state = initialState.bids, action: types.BidsReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_BIDS:
      return {
        ...state,
        data: action.payload.bids
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_BID:
      return {
        ...state,
        current: action.payload.bid
      };
    case constants.SECRET_SHOPPER_BIDS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_BIDS_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function raffles(state = initialState.raffles, action: types.RafflesReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_RAFFLES:
      return {
        ...state,
        data: action.payload.raffles
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_RAFFLE:
      return {
        ...state,
        current: action.payload.raffle
      };
    case constants.SECRET_SHOPPER_RAFFLE_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_RAFFLE_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function tasks(state = initialState.tasks, action: types.TasksReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_TASKS:
      return {
        ...state,
        data: action.payload.tasks
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK:
      return {
        ...state,
        current: action.payload.task
      };
    case constants.SECRET_SHOPPER_SET_URL_RECEIPT:
      return {
        ...state,
        receipt: action.payload.url
      };
    case constants.SECRET_SHOPPER_TASKS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_SET_REQUEST_REMUNERATION:
      return {
        ...state,
        requestsRemuneration: action.payload.logging
      };
    case constants.SECRET_SHOPPER_TASKS_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function receipts(state = initialState.receipts, action: types.ReceiptsReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_RECEIPTS:
      return {
        ...state,
        data: action.payload.receipts
      };
    case constants.SECRET_SHOPPER_RECEIPTS_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function moderation(state = initialState.moderation, action: types.ModerationReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_MODERATION:
      return {
        ...state,
        data: action.payload.moderation
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_MODERATION:
      return {
        ...state,
        current: action.payload.moderation
      };
    case constants.SECRET_SHOPPER_MODERATION_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_MODERATION_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function questions(state = initialState.questions, action: types.QuestionsReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_QUESTIONS:
      return {
        ...state,
        data: action.payload.questions
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTION:
      return {
        ...state,
        current: action.payload.question
      };
    case constants.SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_QUESTION:
      return {
        ...state,
        current: null
      };
    case constants.SECRET_SHOPPER_QUESTIONS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_QUESTIONS_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function schemas(state = initialState.schemas, action: types.SchemaReduceTypes): any {
  switch (action.type) {
    case constants.SECRET_SHOPPER_REDUCE_SET_SCHEMAS:
      return {
        ...state,
        data: action.payload.schemas
      };
    case constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_SCHEMA:
      return {
        ...state,
        current: action.payload.schema
      };
    case constants.SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_SCHEMA:
      return {
        ...state,
        current: null
      };
    case constants.SECRET_SHOPPER_SCHEMAS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        windows: { ...state.windows, [typeWindow]: isShowWindow}
      };
    case constants.SECRET_SHOPPER_SCHEMAS_CHECKOUT_REQUEST:
      return {
        ...state,
        request: {
          error: null,
          loading: action.payload.loading,
        }
      };
    default:
      return state;
  }
}

export function getRemuneration(state: { secretShopper: { tasks: { requestsRemuneration: any; }; }; }){
  return state.secretShopper.tasks.requestsRemuneration
}

export function getUrlReceipt(state: { secretShopper: { tasks: { receipt: any; }; }; }){
  return state.secretShopper.tasks.receipt
}

export default combineReducers({
  bids,
  raffles,
  tasks,
  receipts,
  moderation,
  questions,
  schemas,
  category
})