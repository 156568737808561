import React from 'react';
import { IDishes } from 'domain/types/restaurants.type';
import { Button } from 'primereact/button';

interface IProps {
  item: IDishes | null;
  order: number;
  onClick: () => void;
  setOrder: (id: string, order: number, symbol: string) => void;
}

const ItemKitchen: React.FC<IProps> = (props: any) => {
  const item = props.item;
  const order = props.order;
  const handleClick = props.onClick;
  const setOrder = props.setOrder;

  const priorityUp = (id:string) => {
    setOrder(id, order - 1, 'up');
  }

  const priorityDown = (id:string) => {
    setOrder(id, order + 1, 'down');
  }
  
  return (
    <>
      {item 
        ? <div className="">
            <div className="p-col-12 p-shadow-2" style={{fontSize: '12px'}}>
              
              <div className="dishes-grid-item-content p-flex-start p-flex-row p-col-12 p-p-0" style={{height: 'auto'}}>
                <div className="dishes-grid-item-top p-col-2 p-d-flex p-jc-end p-pl-0 p-pr-0">
                  <div 
                    className="grid-item-img"
                    style={{
                      backgroundImage:
                        `${item?.photo 
                            ? `url(/api/files/${item.photo})` 
                            : `url(${process.env.PUBLIC_URL + "/images/default_empty.png"})`}`
                    }}
                  />
                </div>

                <div className="p-d-flex p-flex-column p-col-7 p-jc-between">
                  <div>
                    <div className="p-d-flex">
                      <div className="dishes-name p-text-center">{item.name}</div>
                    </div>
                    
                    <div className="dishes-description p-text-start">{item.description}</div>
                  </div>

                  <div className="dishes-name p-mt-1">Цена: {item.price} <span>&#8381;</span></div>

                  
                </div>
                
                
                <Button onClick={(e:any) => {
                  e.preventDefault();
                  handleClick()
                }} icon="pi pi-cog" className="p-col-1"/>

                <div className={`p-jc-between p-d-flex p-flex-column`}>
                  <div onClick={() => priorityUp(item.id)}>
                    <i 
                      className="pi pi-angle-up p-col-6 p-text-center" 
                      style={{ cursor: 'pointer'}
                    }></i>
                  </div>
                  <div onClick={() => priorityDown(item.id)}>
                    <i 
                      className="pi pi-angle-down p-col-6 p-text-center" 
                      style={{ cursor: 'pointer'}}
                    ></i>
                  </div>
                </div>
              </div>
              
              
            </div>

            
          </div>
        : <span></span>
      }
    </>
  );
};

export default ItemKitchen;