import React, { useEffect } from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const SecretShopper: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/panel/secret_shopper") navigation("/panel/secret_shopper/bids");
  });
  
  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Тайный покупатель</h1>
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default SecretShopper;