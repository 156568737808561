import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showWindow, setFilters, getRestaurants } from 'domain/actions/restaurants.action';
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from "primereact/checkbox";

export const FiltersContent: React.FC = (props: any) => {
  const { showWindow, cities, categories, setFilters, filters, getRestaurants } = props;

  const [city, setCities] = useState<Array<any> | null>(null);
  const [category, setCategories] = useState<Array<any> | null>(null);
  const [checkedFull, setCheckedFull] = useState(false);
  
  useEffect(() => 
    setCities(filters.cities.length ? filters.cities : null), [filters.cities]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => 
    setCategories(filters.categories.length ? filters.categories : null), [filters.categories]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => 
    setCheckedFull(!filters.isFullness), [filters.isFullness]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => showWindow('filters', false);
  const handleApply = () => {
    setFilters(city, category, [], !checkedFull)
    getRestaurants()
  };

  return (
    <div className="p-col-12 p-p-0">
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-2 p-mb-3">
        <div className="p-col-12 p-md-6">
          <div className="custom-input-text" >
            <label>Город: </label>
            <MultiSelect 
              value={city} 
              options={cities} 
              onChange={(e) => setCities(e.value)} 
              optionLabel="name"
              placeholder="Выберите город"
              showClear
              filter
              filterPlaceholder="Поиск по наименованию"
              maxSelectedLabels={2}
              className="p-col-12 p-p-1 custom-multiselect"
              resetFilterOnHide
              emptyFilterMessage="Нет данных"
              selectedItemsLabel={`${city?.length} выбрано элементов`}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-6">
          <div className="custom-input-text" >
            <label>Категории: </label>
            <MultiSelect 
              value={category} 
              options={categories} 
              onChange={(e) => setCategories(e.value)} 
              optionLabel="name"
              placeholder="Выберите категорию"
              showClear
              filter
              filterPlaceholder="Поиск по наименованию"
              maxSelectedLabels={2}
              className="p-col-12 p-p-1 custom-multiselect"
              resetFilterOnHide
              emptyFilterMessage="Нет данных"
              selectedItemsLabel={`${category?.length} выбрано элементов`}
            />
          </div>
        </div>

        <div className='p-col-12 p-md-6 '>
          <Checkbox
            checked={checkedFull} 
            onChange={e => setCheckedFull(e.checked)}
            inputId="isFullness"
          />
          <label className="p-pl-2" htmlFor="isFullness">
            Не показывать заполненные
          </label>
        </div>
        
      </div>
      
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2">
        <div className="p-col-12 p-md-6">
          <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleCancel}/>
        </div>
        <div className="p-col-12 p-md-6">
          <Button label="Применить" className="p-col-12 p-button-raised p-button-success " onClick={handleApply}/>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  cities: state.cities.cities,
  categories: state.restaurants.categories,
  filters: state.restaurants.filters
})

export default connect(mapStateToProps, {showWindow, setFilters, getRestaurants})(FiltersContent);