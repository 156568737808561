import React from 'react';
import { connect } from 'react-redux';
import { showWindow, deleteRestaurant } from 'domain/actions/restaurants.action';

import { ConfirmDialog } from 'primereact/confirmdialog';

const DeleteWin: React.FC = (props: any) => {
  const { show, showWindow, restaurant, deleteRestaurant } = props;

  const accept = () => deleteRestaurant()
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('delete', false)} 
      message={`Вы действительно хотите безвозвратно удалить заведение ${restaurant?.restaurant?.name}?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.restaurants.windows.delete,
  restaurant: state.restaurants.current
})

export default connect(mapStateToProps, {showWindow, deleteRestaurant})(DeleteWin)