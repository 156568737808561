import React, { useState } from "react";
import { connect } from "react-redux";
import { editDishes, deleteDishes, showWindow } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import DishesFormWin from "./dishes.form";
import { Form } from "react-final-form";
import { Button } from "primereact/button";
import { ICategory, IDishes } from "domain/types/restaurants.type";
import { DeleteWin } from "pages/views/components";

interface IProps {
  item: IDishes | null;
}

export const EditDishesWin: React.FC<IProps> = (props: any) => {
  const [ file, setFile ] = useState(0);
  const [ clear, setClear ] = useState(false);
  const [ visibleDel, setVisibleDel ] = useState(false);
  const { item, categories, kitchens, tags, visible, showWindow, editDishes, deleteDishes } = props;
  
  const handleClearFile = () => { setClear(true) }
  const handleClose = () => showWindow('editDishes', false);
  const handleDelete = (event: any) => {
    event.preventDefault();
    setVisibleDel(true)
  }
  const onSubmit = (values: IDishes) => {
    editDishes(values, file, clear)
  };

  const initialValues = (dishes: IDishes) => {
    const category = categories.filter( (c: ICategory) => c.id === dishes.categoryId )
    const kitchen = kitchens.filter( (k: ICategory) => k.id === dishes.kitchenId )
    const tagIds = dishes.tagIds.map( (tagId: any) => {
      const tag = tags.filter( (k: ICategory) => k.id === tagId.tagId )
      return tag?.[0]
    } )
    
    return {
      ...dishes,
      categoryId: category.length ? category[0] : dishes.categoryId,
      kitchenId: kitchen.length ? kitchen[0] : dishes.kitchenId,
      tagIds: tagIds
    };
  }

  const dataForm = {
    name: "",
    description: "",
    photo: "",
    price: "",
    grams: "",
    categoryId: null,
    kitchenId: null,
    tagIds: []
  }
  
  return (
    <>
      <Dialog header="Редактирование блюда" visible={visible} className="p-col-12 p-p-0 p-md-10 p-lg-8" onHide={handleClose}>
        <Form
          onSubmit={onSubmit}
          initialValues={item ? initialValues(item) : dataForm}
          render={({ handleSubmit }) => (
          
            <form onSubmit={handleSubmit}>
              <DishesFormWin setFile={setFile} file={file} currentImg={item.photo} onClear={handleClearFile}/>

              <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2 p-jc-between">
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
                </div>
                <div className="p-col-12 p-md-6 p-d-flex p-jc-end">
                  <Button label="Удалить" className="p-mr-2 p-button-raised p-button-danger " onClick={handleDelete}/>
                  <Button label="Применить" className=" p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
          )}
        />
      </Dialog>
      
      <DeleteWin show={visibleDel} showWindow={setVisibleDel} item={item} deleteItem={() => deleteDishes(item.id) } />
    </>
    
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.kitchen.categories,
  kitchens: state.restaurants.kitchen.nationality,
  tags: state.restaurants.kitchen.tags,
  visible: state.restaurants.windows.editDishes
})

export default connect(mapStateToProps, { showWindow, editDishes, deleteDishes })(EditDishesWin);