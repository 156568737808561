import React from 'react';
import { connect } from 'react-redux';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { deleteReview, showWindow } from 'domain/actions/settings.action';

interface IProps {
  reviewId: string | null;
}

const DeleteReviewWin: React.FC<IProps> = (props: any) => {
  const { reviewId, show, showWindow, deleteReview } = props;

  const accept = () => deleteReview(reviewId)
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('delete', false)} 
      message={`Вы действительно хотите удалить отзыв?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.settings.windows.delete,
})

export default connect(mapStateToProps, {showWindow, deleteReview})(DeleteReviewWin)