import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showWindow } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { setSchema } from "domain/actions/secretShopper.action";
import QuestionnaireWindow from "../../billing/windows/questionnaire.window";
import { Form } from "react-final-form";
import CreateCouponForm from "./create.coupon.form";
import { error } from "domain/actions/norr.action";
import { createOrderForAccount } from "domain/actions/billing.action";


export const CreateOrderWindows: React.FC = (props: any) => {
  const { visible, questionnaire, current } = props;
  const { showWindow, setSchema, error, createOrderForAccount } = props;

  const handleClose = () => {showWindow('create_task', false);};

  useEffect( () => {
    if(!visible) {
      setSchema(null);
    }
  }, [visible] ) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (values: any) => {
    if(!questionnaire) return error("Анкета не выбрана");

    createOrderForAccount({
      restaurantId: current.id,
      amount: 1,
      typePayment: "pay_acc",
      quantityShoppers: 1,
      questionnaire: questionnaire,
      accountId: values.account.accountId
    });
  }

  return (
    <>
      <Dialog header="Создание счета без оплаты" visible={visible} style={{minWidth: '375px', maxWidth: '1024px'}} onHide={handleClose}>
        <Form
          onSubmit={onSubmit}
          initialData={null}
          styles={{minWidth: '320px'}}
          render={({ handleSubmit, submitting, values }) => (
            <CreateCouponForm handleSubmit={handleSubmit} submitting={submitting} values={values} />
          )}
        />
      </Dialog>

      <QuestionnaireWindow />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  visible: state.restaurants.windows.create_task,
  questionnaire: state.secretShopper.schemas.current 
})

export default connect(mapStateToProps, { 
  showWindow, setSchema, error, createOrderForAccount
})(CreateOrderWindows);