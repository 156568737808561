import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showWindow, getManager, mergeOwner } from 'domain/actions/users.action';

import { Dialog } from 'primereact/dialog';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { TextAreaField } from 'components/form/fields';
import ListUserWindows from './list.users.window';

interface IProps{
  currentId: string | null;
}

const SettingManagerWindows: React.FC<IProps> = (props: any) => {
  const [ selectOwner, setSelectOwners ] = useState<any>({ owner: null});
  const [ selected, setSelected ] = useState(null);
  const { visible, showWindow, currentId, current, owners, mergeOwner, getManager } = props;

  useEffect(() => {
    if(currentId) getManager(currentId)
  }, [getManager, currentId])

  useEffect(() => {
    if(current) {
      const owner = owners.filter( (owner: any) => owner.accountId === current.ownerId )
        .map( (r: any) => ({
          ...r,
          label: `${r.name} (${r.phone})`
        }))
      setSelected(owner.length ? owner[0] : null)
      setSelectOwners({owner: owner.length ? owner[0] : null})
    }
  }, [current, owners])

  const handleClose = () => showWindow('setting_manager', false);
  const handleSelectOwner = () => showWindow('list_users', true);
  const onSelectedOwner = (value: any) => {
    if(value) {
      value.label = `${value.name} ${value.surname ? value.surname : ''} (tel:${value.phone})`
    }
    setSelected(value)
    setSelectOwners({ owner: value })
  }; 

  const onSubmit = (values: any) => mergeOwner(values);

  return (
    <Dialog header="Привязка владельца" visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <div className="p-mb-2 p-col-12">
        <span>Управляющий - {current?.name}</span>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={selectOwner}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="p-col-12 p-p-0">
            
            <div className="p-mt-1 p-mb-2">
              <div className="p-inputgroup">
                <div style={{ width: '100%'}}>
                  <Field
                    name="owner.label"
                    label="Владелец"
                    render={TextAreaField}
                    settings={{
                      disabled: true,
                    }}
                  />
                </div>
                <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                  e.preventDefault();
                  handleSelectOwner();
                }} />
              </div>
            </div>

            <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-between">
              <Button className="p-col-12 p-md-5 p-xl-4 p-button-danger p-mb-2 p-mb-md-0" label="Отмена" onClick={ (e) => {
                e.preventDefault();
                handleClose();
              }}/>
              <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Принять" type="submit"/>
            </div>
          </form>
        )}
      />

      <ListUserWindows type={'owners'} onSubmit={onSelectedOwner} defaultValue={selected} />
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.users.current,
  owners: state.users.owners,
  visible: state.users.windows.setting_manager,
})
export default connect(mapStateToProps, { showWindow, getManager, mergeOwner } )(SettingManagerWindows);