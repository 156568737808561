import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showWindow } from 'domain/actions/restaurants.action';
import QRCode from 'qrcode'

import { Dialog } from "primereact/dialog";
import { showError } from "domain/actions/norr.action";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol;

export const QRWin: React.FC = (props: any) => {
  const { visible, showWindow, restaurant, showError } = props;
  const [ qr, setQr ] = useState<string | null>(null);
  const [ url, setURL ] = useState<string | null>(null);

  const handleClose = () => showWindow('qr_restaurant', false);

  useEffect(() => {
    if(!restaurant) return;

    const host = hostname === 'localhost' ? 'localhost:3000' : hostname === 'admin.3raza.com' ? "3raza.com" : "demo.3raza.com";
    const url = protocol + "//" + host + "#/main/restaurant/";
    QRCode.toDataURL(`${url + restaurant.id}`)
      .then(r => {
        console.log(restaurant);
        setQr(r)
        setURL(url + restaurant.id)
      })
      .catch(err => {
        setQr(null)
        setURL(null)
        showError(err)
      })

  }, [restaurant, showError])

  return (
    <Dialog 
      header="QR-CODE заведения" 
      visible={visible} 
      className="p-col-12 p-p-0 p-md-6 p-lg-3"
      onHide={handleClose}
    >
      <div className="p-col-12 p-d-flex p-jc-center p-flex-column" >
        {!!qr ? <img src={`${qr}`} alt="qr-code заведения"/>
          : `Произошла ошибка: QR-CODE не сформирован `}

          {!!url && 
            <a 
              href={url} 
              className="p-d-block p-d-md-none"
              target="_blank" 
              rel="noopener noreferrer"
            >{url}</a>
          }
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.qr_restaurant,
  restaurant: state.restaurants.current.restaurant
})

export default connect(mapStateToProps, { 
  showWindow, showError
})(QRWin);
