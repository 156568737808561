import React from 'react';
import { connect } from 'react-redux';

import { IHashtag } from 'domain/types/hashtags.type';

import { Field } from 'react-final-form';
import { CheckboxField } from 'components/form/fields';


const HashtagsRestaurantForm: React.FC<any> = (props: any) => { 
  const { hashtags } = props;

  const renderItem = hashtags.map( (hashtag: IHashtag) => (
    <div key={hashtag.id} className="p-col-12 p-md-6 p-lg-4 p-xl-3" >
      <Field
        name={`hashtags.${hashtag.id}`}
        render={CheckboxField}
        inputId={hashtag.id}
      />
      <label htmlFor={hashtag.id} className="p-ml-2" style={{ fontSize: '12px' }}>
        #{hashtag.name.toUpperCase()}
      </label>
    </div>
  ) )

  return (
    <div className="card p-mt-2">
      <div className="p-col-12 p-d-flex p-flex-wrap">
        {renderItem}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  hashtags: state.hashtags.hashtags
})

export default connect(mapStateToProps)(HashtagsRestaurantForm);