import { TextField } from 'components/form/fields';
import React from 'react';
import { Field } from 'react-final-form';

const SocialForm: React.FC = () => {
  return (
    <div className="card-mini p-mt-2">

      <div className="p-col-12 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6">
          <Field
            name="social.facebook" label="facebook:" render={TextField}
            settings={{ placeholder: "https://www.facebook.com/" }}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Field
            name="social.instagram" label="instagram:" render={TextField}
            settings={{ placeholder: "https://www.instagram.com/" }}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Field
            name="social.telegram" label="telegram:" render={TextField}
            settings={{ placeholder: "https://tlgrm.ru/" }}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Field
            name="social.twitter" label="twitter:" render={TextField}
            settings={{ placeholder: "https://twitter.com/" }}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Field
            name="social.vk" label="vk:" render={TextField}
            settings={{ placeholder: "https://vk.com/" }}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Field
            name="social.youtube" label="youtube:" render={TextField}
            settings={{ placeholder: "https://www.youtube.com/" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialForm;