import React from "react";
import { connect } from "react-redux";

import { TabPanel, TabView } from "primereact/tabview";
import Questions from "./questions";
import Schemas from "./schemas";

export const Questionnaires: React.FC = (props: any) => {

  return (
    <div className="content">
      <span className="content-header">Анкеты</span>

      <TabView>
        <TabPanel header="Шаблоны анкет">
          <Schemas />
        </TabPanel>
        <TabPanel header="Вопросы">
          <Questions />
        </TabPanel>
      </TabView>
    </div>
  );
}


export default connect(null, null )(Questionnaires);