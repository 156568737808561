import { combineReducers } from 'redux'
import * as constants from "domain/constants/users.constant";
import * as types from "domain/types/users.type";
import { IStatusResponse } from 'domain/types/static.type';


const initialState = {
  clients: [],
  owners: [],
  current: null,
  windows: { 
    views: false,
    register: false,
    setting_manager: false,
    list_users: false
  },
  request: {
    loading: false,
    error: null
  }
};

export function clients(state = initialState.clients, action: types.ClientsReduceTypes): any {
  switch (action.type) {
    case constants.USER_CLIENT_REDUCE_SET:
      return action.payload.clients;
    default:
      return state;
  }
}

export function owners(state = initialState.owners, action: types.OwnersReduceTypes): any {
  switch (action.type) {
    case constants.USER_OWNER_REDUCE_SET:
      return action.payload.owners;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.OwnersReduceTypes): any {
  switch (action.type) {
    case constants.USER_REDUCE_SET_CURRENT:
      return action.payload.user;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.OwnersReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.USERS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.OwnersReduceTypes): any {
  switch (action.type) {
    case constants.USERS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function getUser(state: { users: { current: any } }) {
  return state.users.current
}

export default combineReducers({
  clients,
  owners,
  current,
  request,
  windows
})