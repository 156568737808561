import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { Outlet } from "react-router-dom";


export const Notification: React.FC = () => {
  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Уведомления(бета)</h1>
        
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default Notification;