import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBidsHelps } from 'domain/actions/restaurants.action';

import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { toDate } from "utils/toDate.util";
// import ActionsColumn from "pages/views/components/table/action.column";

export const BidHelps: React.FC = (props: any) => {
  const { loading, bids, getBidsHelps } = props;
console.log(bids)
  useEffect(() => getBidsHelps(), [getBidsHelps])

  const handleRefresh = () => getBidsHelps();
  // const handleComplete = (id: string) => completeBid(id);
  // const handleDelete = (id: string) => deleteBid(id);

  // let menu = (item: {bid: { id: string } } ) => {
  //   return [
  //     {
  //       label: 'Одобрить', 
  //       icon: 'pi pi-fw pi-pencil', 
  //       command:()=> handleComplete(item.bid.id),
  //     },
  //     {
  //       label: 'Отказать', 
  //       icon: 'pi pi-fw pi-pencil', 
  //       command:()=> handleDelete(item.bid.id),
  //     }
  //   ]
  // }

  // const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} setElement={()=>{}}/>

  return (
    <>
    <span className="content-header">Заявки "Помощь в создании"</span>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
          </div>
        </div>

        <div className="card">
        <DataTable value={bids} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column field="phone" header="Номер телефона"></Column>
          <Column field="comment" header="Комментарий"></Column>
          <Column field="createdAt" header="Дата регистрации" body={(rowData)=>toDate(rowData.createdAt)}></Column>
          {/* <Column body={action} style={{ maxWidth: '5rem' }}></Column> */}
        </DataTable>
        </div>

      </ScrollPanel>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  bids: state.restaurants.bids.helps,
  loading: state.users.request.loading
})

export default connect(mapStateToProps, { getBidsHelps })(BidHelps);