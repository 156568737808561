import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toDateNoTime } from "utils/toDate.util";
import { getRaffles } from 'domain/actions/secretShopper.action';

export const Raffle: React.FC = (props: any) => {
  const { raffles, getRaffles } = props;

  const handleRefresh = () => getRaffles()

  useEffect( () => {
    handleRefresh()
  },[] ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content">
      <span className="content-header">Розыгрыш</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
          </div>
        </div>

        <div className="card">
          <DataTable 
            value={raffles} scrollable scrollHeight="calc(100vh - 310px)" 
            >
            <Column field="restaurant" header="Заведение"></Column>
            <Column field="cost" header="Цена"></Column>
            <Column field="status" header="Статус"></Column>
            <Column field="createdAt" header="Дата создания" body={(rowData)=>toDateNoTime(rowData.createdAt)}></Column>
          </DataTable>
        </div>

      </ScrollPanel>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  raffles: state.secretShopper.raffles.data
})

export default connect(mapStateToProps, {
  getRaffles
})(Raffle);