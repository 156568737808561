import React from "react";
import { connect } from "react-redux";
import { showWindow, saveCoordinate } from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { MaskField } from "components/form/fields";

export const CoordinateDialog: React.FC = (props: any) => {
  const { visible, current } = props;
  const { showWindow, saveCoordinate } = props;
  

  const data = {
    lat: null,
    long: null,
  }

  const handleClose = () => showWindow('coordinate', false);
  const onSubmit = ( values: any) => saveCoordinate(values)

  return (
    <>
      <Dialog header="Координаты заведения" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>
        <Form
          onSubmit={onSubmit}
          initialValues={current?.coordinate ? current.coordinate : data}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-col-12">
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="lat"
                  label="Широта:"
                  render={MaskField}
                  settings={{
                    placeholder: "Укажите широту",
                    mask: "00.000000",
                  }}
                />
              </div>

              <div className="p-col-12">
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="long"
                  label="Долгота:"
                  render={MaskField}
                  settings={{
                    placeholder: "Укажите долготу",
                    mask: "00.000000",
                  }}
                />
              </div>
              
              <div className="p-mt-1 p-d-flex p-jc-end">
                <Button label="Применить" />
              </div>
            </form>
          )}
        /> 

      </Dialog>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  visible: state.restaurants.windows.coordinate
})

export default connect(mapStateToProps, { showWindow, saveCoordinate })(CoordinateDialog);