import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { showWindow, createRestaurant } from 'domain/actions/restaurants.action';

import { ScrollPanel } from 'primereact/scrollpanel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Form } from "react-final-form";
import { Button } from "primereact/button";
import MainForm from "../components/main";
import ContactsForm from "../components/contacts";
import GalleryForm from "../components/gallery";
import SocialForm from "../components/social";
import HashtagsForm from '../components/hashtags';
import WorkingForm from "../components/working";
import { getHashtags } from "domain/actions/hashtags.action";
import { createRestaurantFormat, dataForm, validate } from "./initialData";
import { showError } from "domain/actions/norr.action";
import SingleUploadImage from "components/upload/single.upload";
import { IPhone } from "domain/types/restaurants.type";

export const AddContent: React.FC = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [phones, setPhone] = useState<IPhone[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [file, setFile] = useState(0);
  const { showError, createRestaurant } = props;
  let refGallery = useRef<any>(null);
  let refLogo = useRef<any>(null);
  
  const handleCancel = (event: any) => {
    event.preventDefault();
    showWindow('update', false);
  };

  const onSubmit = (values: any) => {
    values.logotype = file
    const validMsg = validate(values);
    if(validMsg) return showError(validMsg)
    createRestaurant( createRestaurantFormat( values, files, file, phones ) )
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={dataForm}
      render={({ handleSubmit }) => (
        
          <form onSubmit={handleSubmit} >
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} scrollable>
              
                <TabPanel header="Основная информация" >
                  <MainForm>
                    <SingleUploadImage ref={refLogo} label={"Загрузить логотип"} onSelectFIle={setFile} file={file} />
                  </MainForm>
                </TabPanel>
                <TabPanel header="Контакты и Адрес" >
                  <ContactsForm phones={phones} setPhone={setPhone}/>
                </TabPanel>
                <TabPanel header="Галерея">
                  <ScrollPanel style={{maxHeight: '450px'}}>
                    <GalleryForm setFiles={setFiles} files={files} ref={refGallery}/>
                  </ScrollPanel>
                </TabPanel>
                <TabPanel header="Кухня">
                  <div className="p-col-12 p-text-center">- Доступно при редактировании -</div>
                </TabPanel>
                <TabPanel header="Социальные сети">
                  <SocialForm />
                </TabPanel>
                <TabPanel header="Часы работы">
                  <WorkingForm />
                </TabPanel>
                <TabPanel header="Хештеги">
                  <HashtagsForm />
                </TabPanel>
              
            </TabView>
          
            <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2">
              <div className="p-col-12 p-md-6 p-xl-3">
                <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleCancel}/>
              </div>
              <div className="p-col-12 p-md-6 p-xl-3">
                <Button label="Создать" className="p-col-12 p-button-raised p-button-success "/>
              </div>
            </div>
          </form>
      )}
    />
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.add
})

export default connect(mapStateToProps, { 
  showWindow, getHashtags, showError, createRestaurant
})(AddContent);