import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ScrollPanel } from "primereact/scrollpanel";
import { deleteQuestion, getQuestions, showQuestion, showWindowQuestion, getShopperCategories } from "domain/actions/secretShopper.action";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import { Button } from "primereact/button";
import QuestionWindow from "./windows/question.window";
import { DeleteWin } from "pages/views/components";
import { IQuestion } from "domain/types/secretShopper.type";
import FiltersQuestionWindow from "./windows/filters.questions";
import { toCategoryQuestionTemplate, toTypeQuestionTemplate } from "./toBodies";
import { useMediaQuery } from "react-responsive";

export const Questions: React.FC = (props: any) => {
  const [ item, setItem ] = useState<IQuestion | null>(null)
  const [selectSubcategories, setSubcategories] = useState<Array<any>>([]);
  const [category, setCategories] = useState<Array<any>>([]);
  const [ _questions, setQuestions ] = useState<IQuestion[]>([])
  const [ show, setShow ] = useState<boolean>(false)
  const { questions, getQuestions, showQuestion, showWindowQuestion, deleteQuestion, getShopperCategories } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => { return getQuestions() }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { return getShopperCategories() }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { 
    return handleSetFilters()
   }, [questions]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateQuestion = () => showWindowQuestion('views', true);
  const handleFilters = () => showWindowQuestion('filters', true);
  const handleSetFilters = () => {
    
    if(!questions.length) return
    const _subcategories = selectSubcategories && selectSubcategories.length ? [] : questions;
    
    if(selectSubcategories && selectSubcategories.length) {
      
      questions.forEach( (q:any) => {
        
        for(let i=0; i<selectSubcategories.length; ++i){
        
          if(q.subcategory.map( (i:any) => i.categoryId).includes(selectSubcategories[i].id)) _subcategories.push(q)
        }
      } )
    }
    
    const _categories = category && category.length ? [] : _subcategories;
    if(category && category.length){
      _subcategories.forEach( (sub: any) => {
        for(let i=0; i<category.length; i++){
          if(sub.category === category[i]) _categories.push(sub)
        }
      } )
    }
    setQuestions(_categories)
  }
  const handleRefresh = () => {
    getQuestions()
    getShopperCategories()
  };
  const handleViewing = (id: string) => showQuestion(id);
  const handleDelete = (_item: IQuestion) => {
    setItem( () =>{
      return {
        ..._item,
        name: `"Вопрос: ${_item.question}"`
      }
    } )
    setShow(true)
  };
  const onDelete = () => {
    if(item) deleteQuestion(item.id, () => {
      setItem(null)
      setShow(false)
    })
  };

  let menu = (item: IQuestion) => [
    {label: 'Просмотр', icon: 'pi pi-fw pi-pencil', command:()=> handleViewing(item.id) },
    {label: 'Удалить', icon: 'pi pi-fw pi-trash', command:()=> handleDelete(item) }
  ]
  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  
  const renderHeader = () => {
    return (
      <div className="p-d-flex p-jc-between">
        <div className="p-d-flex p-flex-wrap">
          <Button 
            label="Создать" 
            onClick={handleCreateQuestion} 
            className="p-button-raised p-button-success p-button-text p-mr-2"
          />
          <Button 
            label="Обновить" 
            onClick={handleRefresh} 
            className="button-primary-out"
          />
        </div>
        
        <Button icon="pi pi-sliders-h" onClick={handleFilters} className="p-button-raised p-button-info p-button-text p-ml-1"/>
      </div>
    )
  }
  const header = renderHeader();

  return (
    <div className="content">

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 245px)' }}>
        <div className="card">
          <DataTable value={_questions} 
            scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
            header={header} responsiveLayout="stack" breakpoint="768px" stripedRows
            className="table-mobile-rows"
          >

            <Column 
              field="question" header="Вопрос"
              sortable sortField="question"
              style={{fontStyle: "italic"}}
            ></Column>
            <Column 
              field="type" header="Тип"
              body={(rowData) => toTypeQuestionTemplate(rowData.type)}
              sortable sortField="type"
              style={{ maxWidth: '5rem' }}
            ></Column>

            <Column 
              header="Категория" body={(rowData) => toCategoryQuestionTemplate(rowData.category)}
              sortable sortField="category" 
              style={{ maxWidth: '10rem' }}
            ></Column>
            
            <Column body={action} style={{ maxWidth: '4rem' }}></Column>
          </DataTable>
        </div>
      </ScrollPanel>

      <QuestionWindow />
      <DeleteWin show={show} showWindow={setShow} item={item} deleteItem={onDelete} />
      <FiltersQuestionWindow 
        setFilters={handleSetFilters} 
        selectSubcategories={selectSubcategories} 
        setSubcategories={setSubcategories} 
        category={category} 
        setCategories={setCategories} 
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  questions: state.secretShopper.questions.data
})

export default connect( 
  mapStateToProps, 
  { 
    getQuestions, 
    showQuestion, 
    showWindowQuestion,
    deleteQuestion,
    getShopperCategories 
  } )
(Questions);