import React from "react";
import { connect } from "react-redux";
import { login } from 'domain/actions/auth.action';
import { PublicRoute } from "pages/routes/config/TypeRoute";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { PasswordField, TextField } from "components/form/fields";
import { IAuthForm } from 'domain/types/auth.type';
import { Button } from 'primereact/button';
import { Logotype } from '../components';

interface IProps {
  login: (values: IAuthForm) => void
}

export const Auth = (props: IProps) => {
  const { login } = props;
  const formData = {
    login: "",
    password: "",
  }

  const onSubmit = (values: IAuthForm) => login(values)

  return (
    <PublicRoute>
      <div className="p-col-12 p-d-flex p-jc-center p-ai-center" style={{height: '100vh'}}>
        <div className="p-col-12 p-md-4 p-xl-3 p-p-0">
          <div className="p-col-12 p-p-0 p-d-flex p-jc-center p-flex-column p-ai-center">
            <Logotype className="p-d-flex p-jc-center "/>
            <h3 className="p-m-2">Панель администратора</h3>
          </div>
          
          <Form
            onSubmit={onSubmit}
            initialValues={formData}
            render={({ handleSubmit, submitting}) => (
              <form onSubmit={handleSubmit}>
                <div className="p-mt-1 p-mb-2">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="login"
                    label="Логин:"
                    render={TextField}
                    settings={{
                      placeholder: "Введите свой логин",
                    }}
                  />
                </div>

                <div className="p-mt-1 p-mb-2">
                  <Field
                    validate={requiredValidator}
                    name="password"
                    label="Пароль:"
                    render={PasswordField}
                    settings={{
                      placeholder: "Введите пароль",
                      feedback: false,
                    }}
                    icon={["fas", "lock"]}
                  />
                </div>

                <div className="p-mt-1">
                  <Button className="btn btn-primary p-col-12"label="Авторизоваться" disabled={submitting} />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </PublicRoute>
  );
}

export default connect(null, { login })(Auth);