export const SECRET_SHOPPER_REDUCE_SET_CATEGORY = "SECRET_SHOPPER_REDUCE_SET_CATEGORY";
export const SECRET_SHOPPER_SAGA_GET_CATEGORIES = "SECRET_SHOPPER_SAGA_GET_CATEGORIES";

export const SECRET_SHOPPER_SAGA_GET_BIDS = "SECRET_SHOPPER_SAGA_GET_BIDS";
export const SECRET_SHOPPER_SAGA_GET_BID_BY_ID = "SECRET_SHOPPER_SAGA_GET_BID_BY_ID";
export const SECRET_SHOPPER_SAGA_CREATE_TASKS = "SECRET_SHOPPER_SAGA_CREATE_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_BIDS = "SECRET_SHOPPER_REDUCE_SET_BIDS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_BID = "SECRET_SHOPPER_REDUCE_SET_CURRENT_BID";
export const SECRET_SHOPPER_BIDS_CHECKOUT_REQUEST = "SECRET_SHOPPER_BIDS_CHECKOUT_REQUEST";
export const SECRET_SHOPPER_BIDS_IS_SHOW = "SECRET_SHOPPER_BIDS_IS_SHOW";
export const SECRET_SHOPPER_SAGA_CREATE_BID = "SECRET_SHOPPER_SAGA_CREATE_BID";

export const SECRET_SHOPPER_SAGA_GET_RAFFLES = "SECRET_SHOPPER_SAGA_GET_RAFFLES";
export const SECRET_SHOPPER_SAGA_GET_RAFFLE_BY_ID = "SECRET_SHOPPER_SAGA_GET_RAFFLE_BY_ID";
export const SECRET_SHOPPER_REDUCE_SET_RAFFLES = "SECRET_SHOPPER_REDUCE_SET_RAFFLES";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_RAFFLE = "SECRET_SHOPPER_REDUCE_SET_CURRENT_RAFFLE";
export const SECRET_SHOPPER_RAFFLE_IS_SHOW = "SECRET_SHOPPER_RAFFLE_IS_SHOW";
export const SECRET_SHOPPER_RAFFLE_CHECKOUT_REQUEST = "SECRET_SHOPPER_RAFFLE_CHECKOUT_REQUEST";

export const SECRET_SHOPPER_SAGA_GET_TASKS = "SECRET_SHOPPER_SAGA_GET_TASKS";
export const SECRET_SHOPPER_SAGA_GET_TASKS_ARCHIVE = "SECRET_SHOPPER_SAGA_GET_TASKS_ARCHIVE";
export const SECRET_SHOPPER_SAGA_GET_TASK_BY_ID = "SECRET_SHOPPER_SAGA_GET_TASK_BY_ID";
export const SECRET_SHOPPER_REDUCE_SET_TASKS = "SECRET_SHOPPER_REDUCE_SET_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK = "SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK";
export const SECRET_SHOPPER_TASKS_CHECKOUT_REQUEST = "SECRET_SHOPPER_TASKS_CHECKOUT_REQUEST";
export const SECRET_SHOPPER_TASKS_IS_SHOW = "SECRET_SHOPPER_TASKS_IS_SHOW";

export const SECRET_SHOPPER_SAGA_GET_QUESTIONS = "SECRET_SHOPPER_SAGA_GET_QUESTIONS";
export const SECRET_SHOPPER_SAGA_CREATE_QUESTIONS = "SECRET_SHOPPER_SAGA_CREATE_QUESTIONS";
export const SECRET_SHOPPER_SAGA_DELETE_QUESTIONS = "SECRET_SHOPPER_SAGA_DELETE_QUESTIONS";
export const SECRET_SHOPPER_SAGA_UPDATE_QUESTIONS = "SECRET_SHOPPER_SAGA_UPDATE_QUESTIONS";
export const SECRET_SHOPPER_REDUCE_SET_QUESTIONS = "SECRET_SHOPPER_REDUCE_SET_QUESTIONS";
export const SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_QUESTION = "SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_QUESTION";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTIONS = "SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTIONS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTION = "SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTION";
export const SECRET_SHOPPER_QUESTIONS_IS_SHOW = "SECRET_SHOPPER_QUESTIONS_IS_SHOW";
export const SECRET_SHOPPER_SAGA_QUESTIONS_IS_SHOW = "SECRET_SHOPPER_SAGA_QUESTIONS_IS_SHOW";
export const SECRET_SHOPPER_QUESTIONS_CHECKOUT_REQUEST = "SECRET_SHOPPER_QUESTIONS_CHECKOUT_REQUEST";

export const SECRET_SHOPPER_SAGA_GET_SCHEMAS = "SECRET_SHOPPER_SAGA_GET_SCHEMAS";
export const SECRET_SHOPPER_SAGA_CREATE_SCHEMA = "SECRET_SHOPPER_SAGA_CREATE_SCHEMA";
export const SECRET_SHOPPER_SAGA_UPDATE_SCHEMA = "SECRET_SHOPPER_SAGA_UPDATE_SCHEMA";
export const SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_SCHEMA = "SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_SCHEMA";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_SCHEMA = "SECRET_SHOPPER_REDUCE_SET_CURRENT_SCHEMA";
export const SECRET_SHOPPER_REDUCE_SET_SCHEMAS = "SECRET_SHOPPER_REDUCE_SET_SCHEMAS";
export const SECRET_SHOPPER_SCHEMAS_IS_SHOW = "SECRET_SHOPPER_SCHEMAS_IS_SHOW";
export const SECRET_SHOPPER_SAGA_SCHEMA_IS_SHOW = "SECRET_SHOPPER_SAGA_SCHEMA_IS_SHOW";
export const SECRET_SHOPPER_SAGA_SCHEMA_DELETE = "SECRET_SHOPPER_SAGA_SCHEMA_DELETE";
export const SECRET_SHOPPER_SCHEMAS_CHECKOUT_REQUEST = "SECRET_SHOPPER_SCHEMAS_CHECKOUT_REQUEST";

export const SECRET_SHOPPER_SAGA_GET_TASK_MODERATION = "SECRET_SHOPPER_SAGA_GET_TASK_MODERATION";
export const SECRET_SHOPPER_SAGA_GET_MODERATION_BY_ID = "SECRET_SHOPPER_SAGA_GET_MODERATION_BY_ID";
export const SECRET_SHOPPER_REDUCE_SET_MODERATION = "SECRET_SHOPPER_REDUCE_SET_MODERATION";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_MODERATION = "SECRET_SHOPPER_REDUCE_SET_CURRENT_MODERATION";
export const SECRET_SHOPPER_MODERATION_IS_SHOW = "SECRET_SHOPPER_MODERATION_IS_SHOW";
export const SECRET_SHOPPER_SAGA_GET_QUESTIONNAIRE_BY_TASK_ID = "SECRET_SHOPPER_SAGA_GET_QUESTIONNAIRE_BY_TASK_ID";

export const SECRET_SHOPPER_SAGA_GET_RECEIPTS = "SECRET_SHOPPER_SAGA_GET_RECEIPTS";
export const SECRET_SHOPPER_SAGA_GET_RECEIPT_BY_ID = "SECRET_SHOPPER_SAGA_GET_RECEIPT_BY_ID";
export const SECRET_SHOPPER_REDUCE_SET_RECEIPTS = "SECRET_SHOPPER_REDUCE_SET_RECEIPTS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_RECEIPT = "SECRET_SHOPPER_REDUCE_SET_CURRENT_RECEIPT";
export const SECRET_SHOPPER_RECEIPTS_CHECKOUT_REQUEST = "SECRET_SHOPPER_RECEIPTS_CHECKOUT_REQUEST";
export const SECRET_SHOPPER_RECEIPTS_IS_SHOW = "SECRET_SHOPPER_RECEIPTS_IS_SHOW";

export const SECRET_SHOPPER_MODERATION_CHECKOUT_REQUEST = "SECRET_SHOPPER_MODERATION_CHECKOUT_REQUEST";
export const SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT = "SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT";
export const SECRET_SHOPPER_SAGA_SET_RECEIPT = "SECRET_SHOPPER_SAGA_SET_RECEIPT";
export const SECRET_SHOPPER_SET_URL_RECEIPT = "SECRET_SHOPPER_SET_URL_RECEIPT";

export const SECRET_SHOPPER_GET_REMUNERATION_BY_TASK = "SECRET_SHOPPER_GET_REMUNERATION_BY_TASK";
export const SECRET_SHOPPER_SET_REQUEST_REMUNERATION = "SECRET_SHOPPER_SET_REQUEST_REMUNERATION";

export const SECRET_SHOPPER_SAGA_CONTINUE_TASK = "SECRET_SHOPPER_SAGA_CONTINUE_TASK";
export const SECRET_SHOPPER_SAGA_COMPLETE_TASK = "SECRET_SHOPPER_SAGA_COMPLETE_TASK";
export const SECRET_SHOPPER_SAGA_VERIFIED_TASK = "SECRET_SHOPPER_SAGA_VERIFIED_TASK";
export const SECRET_SHOPPER_SAGA_DEACTIVATE_TASK = "SECRET_SHOPPER_SAGA_DEACTIVATE_TASK";
export const SECRET_SHOPPER_SAGA_UPDATE_IN_ARCHIVE = "SECRET_SHOPPER_SAGA_UPDATE_IN_ARCHIVE";