import React from "react";
import { connect } from "react-redux";

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { showWindow, rejectReview, updateRejectReview } from "domain/actions/settings.action";
import { IReview } from "domain/types/settings.type";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextAreaField } from "components/form/fields";
import { Button } from "primereact/button";

interface IProps {
  review: IReview | null;
}

export const ErrorReviewWin: React.FC<IProps> = (props: any) => {
  const { visible, review } = props;
  const { showWindow, rejectReview, updateRejectReview } = props;

  const handleClose = () => showWindow('review_reject', false);
  const onSubmit = (values: any) => {
    if(review.rejects.length) updateRejectReview(review.rejects[0].id, values)
    else rejectReview(review.id, values.comment)
  }

  return (
    <Dialog 
      header={`Описание отказа модерации`} 
      visible={visible} 
      className="p-col-12 p-p-0 p-md-10 p-lg-8 p-xl-6"
      onHide={handleClose}
      maximizable={true}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={review?.rejects?.length ? review.rejects[0] : {}}
        render={({ handleSubmit }) => (
        
          <form onSubmit={handleSubmit}>
            
            <div className="p-mt-1 p-mb-3">
              <Field
                validate={composeValidators(requiredValidator)}
                name="comment"
                label="Текст описания:"
                render={TextAreaField}
                settings={{
                  placeholder: "Опишите причину отказа в публикации отзыва",
                  rows:5,
                }}
              />
            </div>

            <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
              <Button 
                label="Отмена" 
                className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" 
                onClick={(e)=>{
                  e.preventDefault();
                  handleClose()
              }} />
              <Button 
                label="Отправить" 
                className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-success" 
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.settings.windows.review_reject,
})

export default connect(mapStateToProps, { 
  showWindow, rejectReview, updateRejectReview
})(ErrorReviewWin);