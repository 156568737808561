import * as validators from 'components/form/validators';

import { ICategory, IPhone, IPostRestaurant } from "domain/types/restaurants.type";

export function createRestaurantFormat(data: any, gallery: Array<File>, file: any, phones: Array<IPhone>): IPostRestaurant {
  const address = `${data.city.value.replace(`${data.city?.data.city_with_type}, `, '')}`;
  const city = {
    name: data.city?.data.city,
    region: data.city?.data.region_kladr_id
  }

  const hashtags = [];
  if(data.hashtags){
    for(let key in data.hashtags){
      if(data.hashtags[key]) hashtags.push(key)
    }
  }

  return {
    name: data.name ? data.name : "",
    telephone: data.telephone ? data.telephone : "",
    addInfo: data.addInfo,
    address: address,
    description: data.description ? data.description : "",
    comment: data.comment ? data.comment : "",
    city: city,
    gallery: gallery,
    logotype: file,
    categories: data.categories.map( (category: ICategory) => category.id),
    social: {
      facebook: data.facebook ? data.facebook : "",
      instagram: data.instagram ? data.instagram : "",
      telegram: data.telegram ? data.telegram : "",
      twitter: data.twitter ? data.twitter : "",
      vk: data.vk ? data.vk : "",
      youtube: data.youtube ? data.youtube : ""
    },
    workingHours: data.workingHours ? data.workingHours : "",
    hashtags: hashtags,
    addPhones: phones
  }
}

export function validate(values: IPostRestaurant): string | undefined {

  if (
    validators.requiredValidator(values.name) ||
    // (validators.requiredValidator(values.telephone) && validators.phoneValidator(values.telephone)) || 
    validators.requiredValidator(values.addInfo.averageReceipt) || 
    // (validators.requiredValidator(values.addInfo.phoneOwner) && validators.phoneValidator(values.addInfo.phoneOwner)) || 
    (validators.requiredValidator(values.city) && validators.fullAddressValidator(values.city))
  ) {
    return "Не полностью заполнена основная информация";
  }

  if(!values.categories.length) return "Не выбрана категория заведения";
  if(!values.logotype) return "Логотип не установлен";

  if(!!validators.fullAddressValidator(values.city)){
    return "Не полностью заполнен адрес заведения";
  }

  if (
    (validators.requiredValidator(values.workingHours.monday) && validators.timeFormatValidator(values.workingHours.monday)) || 
    (validators.requiredValidator(values.workingHours.tuesday) && validators.timeFormatValidator(values.workingHours.tuesday)) ||
    (validators.requiredValidator(values.workingHours.wednesday) && validators.timeFormatValidator(values.workingHours.wednesday)) ||
    (validators.requiredValidator(values.workingHours.thursday) && validators.timeFormatValidator(values.workingHours.thursday)) ||
    (validators.requiredValidator(values.workingHours.friday) && validators.timeFormatValidator(values.workingHours.friday)) ||
    (validators.requiredValidator(values.workingHours.saturday) && validators.timeFormatValidator(values.workingHours.saturday)) ||
    (validators.requiredValidator(values.workingHours.sunday) && validators.timeFormatValidator(values.workingHours.sunday))
  ) {
    return 'Не полностью заполнена информация "часы работы"';
  }

  return undefined;
}

export const dataForm = {
  name: "",
  city: null,
  categories: [],
  address: "",
  telephone: "",
  email: "",
  logo: null,
  rating: "",
  description: "",
  gallery: [],
  workingHours: {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: ""
  },
  addInfo: {
    phoneOwner:"",
    addressEntry:"",
    addPhone:"[]",
    minPriceHookah:"",
    metro: "",
    averageReceipt: ""
  },
  social: {
    facebook: "",
    instagram: "",
    telegram: "",
    twitter: "",
    vk: "",
    youtube:""
  },
}