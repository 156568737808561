import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/auth.type';
import * as actions from "domain/actions/auth.action";
import * as constants from "domain/constants/auth.constant";
import { Result } from 'domain/types/static.type';
import { AuthController } from 'domain/controllers/Auth.controller';

const api = new ApiController();
const auth = new AuthController();

export async function authorizationFetch(values: types.IAuthForm): Promise<Result<string>> {
  return await api.post(`/admin/login`, values)
}

export async function getAccountMeFetch(): Promise<Result<object | undefined>> {
  return await api.get(`/administrators/current`)
}

export function* login(action: any): any{
  yield put(actions.reqAuth(true))

  const values = action.payload.values;
  const token = yield call(authorizationFetch, values);

  if(token.type === "success"){
    // localStorage.setItem("access_token", token.value.access_token);
    auth.setTokenStorage(token.value)
    const me = yield call(getAccountMeFetch);
    if(me) {
      yield put(actions.setAuthenticated(true))
    //   yield put(setCurrentMe(me))
        console.log(me)
    };
  }else {
    console.log(token, "error token")
  }

  yield put(actions.reqAuth(false))
}

export function* logout(): any{
  localStorage.removeItem("access_token");
  yield put(actions.setAuthenticated(false))
    
  window.location.reload();
}

export function* currentMe(): any{
  yield put(actions.reqAuth(true))

  const response = yield call(getAccountMeFetch);

  if(response.type === "success"){
    yield put(actions.setAuthenticated(true))
  }else {
    console.log(response, "error token")
  }

  yield put(actions.reqAuth(false))
}

export function* watchAuth() {
  yield takeEvery(constants.AUTH_SAGA_LOGIN, login)
  yield takeEvery(constants.AUTH_SAGA_LOGOUT, logout)
  yield takeEvery(constants.AUTH_SAGA_CURRENT_ME, currentMe)
}

export default function* authSagas() {
  yield fork(watchAuth)
}