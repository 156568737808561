import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from "redux-devtools-extension";
import { save } from "redux-localstorage-simple"

import rootReducer from "./reducers";

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const composedEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware), applyMiddleware(save()));
  return {
    ...createStore(rootReducer, composedEnhancer),
    runSaga: sagaMiddleware.run,
  }
}