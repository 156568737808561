import React from 'react';
import { connect } from 'react-redux';
import { showWindow } from 'domain/actions/coupons.action';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { ICoupon } from 'domain/types/coupons.type';

interface IProps {
  coupon: ICoupon | null;
  handleDelete: (coupon: ICoupon) => void;
}

const DeleteCouponWin: React.FC<IProps> = (props: any) => {
  const { coupon, show, showWindow, handleDelete } = props;

  const accept = () => handleDelete(coupon)
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('delete', false)} 
      message={`Вы действительно хотите удалить Купон?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.coupons.windows.delete,
})

export default connect(mapStateToProps, {showWindow})(DeleteCouponWin)