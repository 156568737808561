import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBidsUpdate, showWindow } from 'domain/actions/restaurants.action';

import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { toDate } from "utils/toDate.util";
import ActionsColumn from "pages/views/components/table/action.column";
import BidUpdateWin from "./windows/bid.update.window";

export const BidUpdateRestaurants: React.FC = (props: any) => {
  const { loading, bids, getBidsUpdate, showWindow } = props;
  const [ currentId, setCurrentId ] = useState<string | null>(null);

  useEffect(() => getBidsUpdate(), [getBidsUpdate])

  const handleRefresh = () => getBidsUpdate();
  const handleViewing = () => showWindow('bid_update', true);

  let menu = (item: { id: string, status: string; }) => [
    {
      label: 'Просмотр заявки', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(),
    }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} setElement={()=>setCurrentId(rowData.bid.id)}/>

  return (
    <>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
          </div>
        </div>

        <div className="card">
          <DataTable value={bids} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
            <Column field="restaurant.name" header="Заведение" ></Column>
            <Column field="restaurant.telephone" header="Телефон" ></Column>
            <Column field="createdAt" header="Дата регистрации" body={(rowData)=>toDate(rowData.bid.createdAt)}></Column>
            <Column body={action} style={{ maxWidth: '5rem' }}></Column>
          </DataTable>
        </div>

      </ScrollPanel>

      <BidUpdateWin currentId={currentId}/>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  bids: state.restaurants.bids.update,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { getBidsUpdate, showWindow })(BidUpdateRestaurants);