import React from "react";
import { connect } from "react-redux";
import { showWindow, updateKitchen } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { ICategory } from "domain/types/restaurants.type";
import FormCategory from "./form.category.window";

interface IProps {
  selected: ICategory | null;
}

export const EditKitchenWin: React.FC<IProps> = (props: any) => {
  const { visible, selected } = props;
  const { showWindow, updateKitchen } = props;

  const handleClose = () => showWindow('editKitchen', false);
  const onSubmit = (values: ICategory) => updateKitchen( values );

  const dataForm = {
    id: '',
    name: "",
    description: "",
  }

  return (
    <Dialog header="Редактирование кухни" visible={visible} className="p-col-12 p-p-0 p-md-6 p-lg-4" onHide={handleClose}>
      <FormCategory initialValues={selected ? selected : dataForm} handleClose={handleClose} onSubmit={onSubmit} />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.editKitchen
})

export default connect(mapStateToProps, { 
  showWindow, updateKitchen
})(EditKitchenWin);