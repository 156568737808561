import React from 'react';
import { connect } from "react-redux";

import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';
import { showWindow, sendLink } from 'domain/actions/billing.action';
import { Field, Form } from 'react-final-form';
import { MaskField, TextField } from 'components/form/fields';
import { composeValidators, emailValidator, phoneValidator } from 'components/form/validators';

export const SendLinkWindow: React.FC<any> = (props: any) => {
  const { 
    visible, 
    showWindow,
    sendLink
  } = props;
  
  const handleClose = () => showWindow('send_link', false)
  const onSubmit = (values: any) => {
    sendLink(values);
  }

  const initialValues = {
    phone: "", email: "",
  }

  return (
    <>
      <Dialog
        className="p-col-12 p-md-8 p-lg-6 p-p-0"
        header="Отправить ссылку"
        visible={visible}
        onHide={handleClose}
        modal
        draggable={false}
      >
        <Form
          onSubmit={onSubmit}
          initialData={initialValues}
          styles={{minWidth: '320px'}}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
                <div className='p-col-12 p-md-6'>
                  <Field
                    validate={composeValidators(phoneValidator)}
                    name="phone"
                    label="Телефон:"
                    render={MaskField}
                    settings={{
                      placeholder: "Введите номер телефона заведения",
                      mask: "+00000000000",
                    }}
                  />
                </div>
        
                <div className="p-col-12 p-md-6">
                  <Field
                    validate={composeValidators(
                      emailValidator
                    )}
                    name="email"
                    label="e-mail:"
                    render={TextField}
                  />
                </div>
              </div>

              <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
                <Button label="Отправить" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-success" />
              </div>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.billing.windows.send_link
})

export default connect(mapStateToProps, 
  {
    showWindow,
    sendLink
  }
)(SendLinkWindow);