import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/city.type';
import * as actions from "domain/actions/cities.action";
import * as constants from "domain/constants/cities.constant";
import { Result } from 'domain/types/static.type';

const api = new ApiController();

export async function getCitiesFetch(): Promise<Result<Array<types.ICity>>> {
  return await api.get(`/cities`)
}

export function* getCities(): any{
  yield put(actions.reqCities(true))

  const response = yield call(getCitiesFetch);

  if(response.type === "success"){
    yield put(actions.setCities(response.value))
  }else {
    console.log(response, "error cities")
  }

  yield put(actions.reqCities(false))
}

export function* watch() {
  yield takeEvery(constants.CITIES_SAGA_GET_ALL, getCities)
  
}

export default function* sagas() {
  yield fork(watch)
}