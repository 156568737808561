export const RESTAURANT_SAGA_GET = "RESTAURANT_SAGA_GET";
export const RESTAURANT_SAGA_GET_BY_ID = "RESTAURANT_SAGA_GET_BY_ID";
export const RESTAURANT_SAGA_GET_MANAGER_BY_RESTAURANT = "RESTAURANT_SAGA_GET_MANAGER_BY_RESTAURANT";
export const RESTAURANT_SAGA_POST = "RESTAURANT_SAGA_POST";
export const RESTAURANT_SAGA_PUT = "RESTAURANT_SAGA_PUT";
export const RESTAURANT_SAGA_GET_BIDS = "RESTAURANT_SAGA_GET_BIDS";
export const RESTAURANT_SAGA_GET_BIDS_HELPS = "RESTAURANT_SAGA_GET_BIDS_HELPS";
export const RESTAURANT_SAGA_BIND_OWNER = "RESTAURANT_SAGA_BIND_OWNER";
export const RESTAURANT_SAGA_GET_ALL_UPDATE = "RESTAURANT_SAGA_GET_ALL_UPDATE";
export const RESTAURANT_SAGA_GET_BID_UPDATE_BY_ID = "RESTAURANT_SAGA_GET_BID_UPDATE_BY_ID";
export const RESTAURANT_SAGA_GET_KITCHENS = "RESTAURANT_SAGA_GET_KITCHENS";
export const RESTAURANT_SAGA_GET_TAGS_DISHES = "RESTAURANT_SAGA_GET_TAGS_DISHES";
export const RESTAURANT_SAGA_GET_KITCHEN_BY_ID = "RESTAURANT_SAGA_GET_KITCHEN_BY_ID";
export const RESTAURANT_SAGA_GET_CATEGORIES_DISHES = "RESTAURANT_SAGA_GET_CATEGORIES_DISHES";
export const RESTAURANT_SAGA_GET_CATEGORIES_HOOKAH = "RESTAURANT_SAGA_GET_CATEGORIES_HOOKAH";
export const RESTAURANT_SAGA_GET_CATEGORY_DISHES_BY_ID = "RESTAURANT_SAGA_GET_CATEGORY_DISHES_BY_ID";
export const RESTAURANT_SAGA_GET_DISHES = "RESTAURANT_SAGA_GET_DISHES";
export const RESTAURANT_SAGA_SET_ORDERS_DISHES = "RESTAURANT_SAGA_SET_ORDERS_DISHES";
export const RESTAURANT_SAGA_SET_ORDERS_HOOKAH = "RESTAURANT_SAGA_SET_ORDERS_HOOKAH";
export const RESTAURANT_SAGA_GET_DISHES_BY_RESTAURANT_ID = "RESTAURANT_SAGA_GET_DISHES_BY_RESTAURANT_ID";
export const RESTAURANT_SAGA_GET_CATEGORIES = "RESTAURANT_SAGA_GET_CATEGORIES";
export const RESTAURANT_SAGA_POST_CATEGORIES_DISHES = "RESTAURANT_SAGA_POST_CATEGORIES_DISHES";
export const RESTAURANT_SAGA_POST_CATEGORIES_HOOKAH = "RESTAURANT_SAGA_POST_CATEGORIES_HOOKAH";
export const RESTAURANT_SAGA_POST_YOUTUBE = "RESTAURANT_SAGA_POST_YOUTUBE";
export const RESTAURANT_SAGA_POST_RUTUBE = "RESTAURANT_SAGA_POST_RUTUBE";
export const RESTAURANT_SAGA_DELETE_VIDEO_RUTUBE = "RESTAURANT_SAGA_DELETE_VIDEO_RUTUBE";
export const RESTAURANT_SAGA_GET_VIDEOS_RUTUBE = "RESTAURANT_SAGA_GET_VIDEOS_RUTUBE";
export const RESTAURANT_SAGA_PUT_CATEGORIES_DISHES = "RESTAURANT_SAGA_PUT_CATEGORIES_DISHES";
export const RESTAURANT_SAGA_PUT_CATEGORIES_HOOKAH = "RESTAURANT_SAGA_PUT_CATEGORIES_HOOKAH";
export const RESTAURANT_SAGA_POST_KITCHENS = "RESTAURANT_SAGA_POST_KITCHENS";
export const RESTAURANT_SAGA_PUT_KITCHENS = "RESTAURANT_SAGA_PUT_KITCHENS";
export const RESTAURANT_SAGA_POST_DISHES = "RESTAURANT_SAGA_POST_DISHES";
export const RESTAURANT_SAGA_COPY_DISHES = "RESTAURANT_SAGA_COPY_DISHES";
export const RESTAURANT_SAGA_POST_HOOKAH = "RESTAURANT_SAGA_POST_HOOKAH";
export const RESTAURANT_SAGA_PUT_DISHES = "RESTAURANT_SAGA_PUT_DISHES";
export const RESTAURANT_SAGA_DELETE_DISHES = "RESTAURANT_SAGA_DELETE_DISHES";
export const RESTAURANT_SAGA_DELETE_CATEGORY_DISHES = "RESTAURANT_SAGA_DELETE_CATEGORY_DISHES";
export const RESTAURANT_SAGA_DELETE_CATEGORY_NATIONAL = "RESTAURANT_SAGA_DELETE_CATEGORY_NATIONAL";
export const RESTAURANT_SAGA_PUT_HOOKAH = "RESTAURANT_SAGA_PUT_HOOKAH";
export const RESTAURANT_SAGA_DELETE_HOOKAH = "RESTAURANT_SAGA_DELETE_HOOKAH";
export const RESTAURANT_SAGA_DELETE = "RESTAURANT_SAGA_DELETE";
export const RESTAURANT_SAGA_SET_ACTIVE = "RESTAURANT_SAGA_SET_ACTIVE";
export const RESTAURANT_SAGA_COMPLETE_BID = "RESTAURANT_SAGA_COMPLETE_BID";
export const RESTAURANT_SAGA_DELETE_BID = "RESTAURANT_SAGA_DELETE_BID";
export const RESTAURANT_SAGA_DENY_BID = "RESTAURANT_SAGA_DENY_BID";
export const RESTAURANT_SAGA_POST_COORDINATE = "RESTAURANT_SAGA_POST_COORDINATE";
export const RESTAURANT_SAGA_SET_FULLNESS = "RESTAURANT_SAGA_SET_FULLNESS";

export const RESTAURANT_SAGA_COMPLETE_BID_UPDATE = "RESTAURANT_SAGA_COMPLETE_BID_UPDATE";
export const RESTAURANT_SAGA_DENY_BID_UPDATE = "RESTAURANT_SAGA_DENY_BID_UPDATE";

export const RESTAURANT_REDUCE_SET = "RESTAURANT_REDUCE_SET";
export const RESTAURANT_REDUCE_SET_ALL_COUNT = "RESTAURANT_REDUCE_SET_ALL_COUNT";
export const RESTAURANT_REDUCE_SET_PAGE = "RESTAURANT_REDUCE_SET_PAGE";
export const RESTAURANT_REDUCE_SET_SIZE_PAGE = "RESTAURANT_REDUCE_SET_SIZE_PAGE";
export const RESTAURANT_REDUCE_SET_CURRENT = "RESTAURANT_REDUCE_SET_CURRENT";
export const RESTAURANT_REDUCE_SET_CURRENT_UPDATE = "RESTAURANT_REDUCE_SET_CURRENT_UPDATE";
export const RESTAURANT_REDUCE_SET_CURRENT_BID_UPDATE = "RESTAURANT_REDUCE_SET_CURRENT_BID_UPDATE";
export const RESTAURANT_REDUCE_SET_BIDS = "RESTAURANT_REDUCE_SET_BIDS";
export const RESTAURANT_REDUCE_SET_BIDS_HELPS = "RESTAURANT_REDUCE_SET_BIDS_HELPS";
export const RESTAURANT_REDUCE_SET_BIDS_UPDATE = "RESTAURANT_REDUCE_SET_BIDS_UPDATE";
export const RESTAURANT_REDUCE_SET_KITCHENS = "RESTAURANT_REDUCE_SET_KITCHENS";
export const RESTAURANT_REDUCE_SET_CATEGORIES_DISHES = "RESTAURANT_REDUCE_SET_CATEGORIES_DISHES";
export const RESTAURANT_REDUCE_SET_RESTAURANT_DISHES = "RESTAURANT_REDUCE_SET_RESTAURANT_DISHES";
export const RESTAURANT_REDUCE_SET_TAGS_DISHES = "RESTAURANT_REDUCE_SET_TAGS_DISHES";
export const RESTAURANT_REDUCE_SET_RESTAURANT_MANAGER = "RESTAURANT_REDUCE_SET_RESTAURANT_MANAGER";
export const RESTAURANT_REDUCE_SET_RESTAURANT_HOOKAH = "RESTAURANT_REDUCE_SET_RESTAURANT_HOOKAH";
export const RESTAURANT_REDUCE_SET_CATEGORIES_HOOKAH = "RESTAURANT_REDUCE_SET_CATEGORIES_HOOKAH";
export const RESTAURANT_REDUCE_SET_CATEGORIES = "RESTAURANT_REDUCE_SET_CATEGORIES";
export const RESTAURANT_REDUCE_SET_RESTAURANT_FILTERS = "RESTAURANT_REDUCE_SET_RESTAURANT_FILTERS";
export const RESTAURANTS_REDUCE_SET_FILTER_LIKE = "RESTAURANTS_REDUCE_SET_FILTER_LIKE";


export const RESTAURANT_CHECKOUT_REQUEST = "RESTAURANT_CHECKOUT_REQUEST";
export const RESTAURANT_IS_SHOW = "RESTAURANT_IS_SHOW";
export const RESTAURANT_SAGA_CURRENT_IS_SHOW_WINDOW = "RESTAURANT_SAGA_CURRENT_IS_SHOW_WINDOW";
export const RESTAURANT_SAGA_SHOW_ERROR = "RESTAURANT_SAGA_SHOW_ERROR";

export const RESTAURANT_SAGA_UPLOAD_XLS = "RESTAURANT_SAGA_UPLOAD_XLS";
export const RESTAURANT_SAGA_UPLOAD_XLS_DISHES = "RESTAURANT_SAGA_UPLOAD_XLS_DISHES";
export const RESTAURANT_SAGA_UPDATE_AVERAGE = "RESTAURANT_SAGA_UPDATE_AVERAGE";