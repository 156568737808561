interface IProps {
  videoId: string;
  defaultImg?: string;
  className?: string;
}

export const RutubePreview = (props: IProps) => {
  const {videoId, defaultImg, className = ''} = props 
  const index = videoId.indexOf('/')
  
  const _videoId = index > -1 ? videoId.substring(0, index) : videoId
  
  const src = `/api/files/${defaultImg}`
  const onError = (e: any) => e.target.src = process.env.PUBLIC_URL + "/images/default_empty.png"
  const preview = `https://preview.rutube.ru/preview/${_videoId}.webp`

  return (
    <img src={preview ? preview : src} className={className} onError={onError} alt={`rutube video id ${videoId}`} />
  )
};