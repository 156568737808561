import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/settings.type';
import * as actions from "domain/actions/settings.action";
import * as constants from "domain/constants/settings.constant";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';

const api = new ApiController();
const norr = new NorrController();

export async function getReviewsFetch(): Promise<Result<types.IReview[]>> {
  return await api.get(`/reviews`)
}

export async function putReviewFetch(reviewId: string, isPublished: boolean): Promise<Result<any>> {
  return await api.put(`/reviews/published/${reviewId}`, {isPublished})
}

export async function postRejectReviewFetch(data: types.IReviewReject): Promise<Result<void>> {
  return await api.post(`/reviews/reject`, data)
}

export async function putRejectReviewFetch(id: string, data: types.IReviewReject): Promise<Result<void>> {
  return await api.put(`/reviews/reject/${id}`, data)
}

export async function deleteReviewFetch(stockId: string): Promise<Result<any>> {
  return await api.delete(`/reviews/${stockId}`)
}

export function* getReviews(): any{
  yield put(actions.reqSettings(true))

  const response = yield call(getReviewsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setReviews(response.value))
  })

  yield put(actions.reqSettings(false))
}

export function* publishReview(action: any): any{
  const data = action.payload;
  yield put(actions.reqSettings(true))

  const response = yield call(putReviewFetch, data.id, data.isPublished )
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('review_update', false))
    yield call(getReviews)
  },  `${data.isPublished ? "Отзыв опубликован" : "Отзыв снят с публикации"} `)

  yield put(actions.reqSettings(false))
}

export function* rejectReview(action: any): any{
  const data = action.payload;
  const response = yield call(postRejectReviewFetch, data )

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('review_reject', false))
    yield call(getReviews)
  },  "Описание ошибки опубликовано")
}

export function* updateRejectReview(action: any): any{
  const data = action.payload;
  const response = yield call(putRejectReviewFetch, data.rejectId, data.data )

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('review_reject', false))
    yield call(getReviews)
  },  "Описание ошибки опубликовано")
}

export function* deleteReview(action: any): any{
  const reviewId = action.payload.id;

  const response = yield call(deleteReviewFetch, reviewId);
  yield call(norr.processing, response, function *(){
    yield call(getReviews)
  }, "Отзыв удален")
}

export function* watch() {
  yield takeEvery(constants.SETTINGS_SAGA_GET_REVIEWS, getReviews)
  yield takeEvery(constants.SETTINGS_SAGA_PUT_REVIEW_PUBLISH, publishReview)
  yield takeEvery(constants.SETTINGS_SAGA_POST_REVIEW_REJECT, rejectReview)
  yield takeEvery(constants.SETTINGS_SAGA_PUT_REVIEW_REJECT, updateRejectReview)
  yield takeEvery(constants.SETTINGS_SAGA_DELETE_REVIEW, deleteReview)
}

export default function* sagas() {
  yield fork(watch)
}
