import { fork, takeEvery, call, put } from 'redux-saga/effects'
import * as constants from 'domain/constants/notification.constant';
import * as actions from "domain/actions/notification.action";
import { Result } from 'domain/types/static.type';
import { INotification } from 'domain/types/notification.type';
import ApiController from 'domain/controllers/Api.controller';
import { NorrController } from 'domain/controllers/Response.controller';

const api = new ApiController();
const norr = new NorrController();

export async function getSubscribeToAllFetch(): Promise<Result<any>> {
  return await api.get(`/notifications/subscribe`)
}

export async function sendNotificationToAllFetch(data: INotification): Promise<Result<any>> {
  return await api.post(`/notifications/all`, data)
}

export async function uploadXlsFetch(file: any): Promise<Result<any>> {
  const fd = new FormData();
  fd.append('xls', file, file.name)
  return await api.post(`/parser/xls`, fd) 
}

export async function uploadXlsEmailsFetch(file: any): Promise<Result<any>> {
  const fd = new FormData();
  fd.append('xls', file, file.name)
  return await api.post(`/parser/xls/emails`, fd)
}

export async function MailingFetch(mail: any): Promise<Result<any>> {
  return await api.post(`/parser/mailing`, mail)
}

export function* getSubscribeToAll(): any{
  const response = yield call(getSubscribeToAllFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setNotifications(response.value));
  })
}

export function* parseEmailsString(action: any): any {
  const file = action.payload.file;

  const response = yield call(uploadXlsEmailsFetch, file);

  yield call(norr.processing, response, function *(){})
}

export function* uploadXls(action: any): any {
  const file = action.payload.file;

  const response = yield call(uploadXlsFetch, file);

  yield call(norr.processing, response, function *(){
    

    console.log(response.value)
    yield put(actions.setDataParse(response.value));
  })
}

export function* mailing(action: any): any {
  const response = yield call(MailingFetch, {
    emails: 'cool.timch2014@yandex.ru, sergey.timoshenko.iv@gmail.com',
    header: 'Проверка рассылки',
    text: 'Проверяем рассылку писем на большое кол-во емаилов'
  });

  yield call(norr.processing, response, function *(){})
}

export function* sendNotificationToAll(action: any): any{
  const response = yield call(sendNotificationToAllFetch, action.payload);
  yield call(norr.processing, response, function *(){}, "Уведомление успешно отправлено")
}

export function* watch() {
  yield takeEvery(constants.NOTIFICATION_SAGA_SEND_ALL, sendNotificationToAll)
  yield takeEvery(constants.NOTIFICATION_SAGA_GET_SUBSCRIBE, getSubscribeToAll)
  yield takeEvery(constants.NOTIFICATION_SAGA_UPLOAD_XLS, uploadXls)
}

export default function* sagas() {
  yield fork(watch)
}