import React, { useState } from "react";
import { connect } from "react-redux";
import { editHookah, deleteHookah, showWindow } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import HookahFormWin from "./hookah.form";
import { Form } from "react-final-form";
import { Button } from "primereact/button";
import { ICategory, IHookah } from "domain/types/restaurants.type";
import { DeleteWin } from "pages/views/components";

interface IProps {
  item: IHookah | null;
}

export const EditHookahWin: React.FC<IProps> = (props: any) => {
  const [ file, setFile ] = useState(0);
  const [ visibleDel, setVisibleDel ] = useState(false);
  const { item, categories, visible, showWindow, editHookah, deleteHookah } = props;

  const handleClose = () => showWindow('editHookah', false);
  const handleDelete = (event: any) => {
    event.preventDefault();
    setVisibleDel(true)
  }
  const onSubmit = (values: IHookah) => {
    editHookah(values, file)
  };

  const initialValues = (hookah: IHookah) => {
    const category = categories.filter( (c: ICategory) => c.id === item.categoryId )

    return {
      ...hookah, 
      categoryId: category.length ? category[0] : hookah.categoryId
    };
  }

  const dataForm = {
    categoryId: null,
    name: "",
    description: "",
    photo: "",
    price: ""
  }

  return (
    <>
      <Dialog header="Редактирование кальяна" visible={visible} className="p-col-12 p-p-0 p-md-10 p-lg-8" onHide={handleClose}>
        <Form
          onSubmit={onSubmit}
          initialValues={item ? initialValues(item) : dataForm}
          render={({ handleSubmit }) => (
          
            <form onSubmit={handleSubmit}>
              <HookahFormWin setFile={setFile} file={file} currentImg={item.photo}/>

              <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2 p-jc-between">
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
                </div>
                <div className="p-col-12 p-md-6 p-d-flex p-jc-end">
                  <Button label="Удалить" className="p-mr-2 p-button-raised p-button-danger " onClick={handleDelete}/>
                  <Button label="Применить" className=" p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
          )}
        />
      </Dialog>
      
      <DeleteWin show={visibleDel} showWindow={setVisibleDel} item={item} deleteItem={() => deleteHookah(item.id) } />
    </>
    
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.hookah.categories,
  visible: state.restaurants.windows.editHookah
})

export default connect(mapStateToProps, { showWindow, editHookah, deleteHookah })(EditHookahWin);