import * as constants from "domain/constants/billing.constant";
import * as types from "domain/types/billing.type";
import { orderBy } from "lodash";

export function buyCoupons(data: types.IBuyCouponSaga){
  return {
    type: constants.BILLING_SAGA_BUY_COUPONS,
    payload: data
  }
}

export function activeCoupon(id: string){
  return {
    type: constants.BILLING_SAGA_ACTIVE_COUPONS,
    payload: { id }
  }
}

export function setArchive(id: string){
  return {
    type: constants.BILLING_SAGA_ARCHIVE_COUPONS,
    payload: { id }
  }
}

export function sendLink(data: {phone: string, email: string}){
  return {
    type: constants.BILLING_SAGA_SEND_ORDER,
    payload: data
  }
}

export function getOrders(){
  return {
    type: constants.BILLING_SAGA_GET_ORDERS
  }
}

export function getOrdersArchive(){
  return {
    type: constants.BILLING_SAGA_GET_ARCHIVE_ORDERS
  }
}

export function setOrders(orders: any[]){
  const _orders = orderBy(orders, ['createdAt'], ['desc'])
  return {
    type: constants.BILLING_REDUCE_SET_ORDERS,
    payload: _orders
  }
}

export function setOrder(order: any){
  return {
    type: constants.BILLING_REDUCE_SET_CURRENT_ORDER,
    payload: order
  }
}

export function getOrder(id: string){
  return {
    type: constants.BILLING_SAGA_GET_ORDER,
    payload: { id }
  }
}

export function createOrder(data: types.ICreateOrderResponse){
  return {
    type: constants.BILLING_SAGA_CREATE_ORDER,
    payload: data
  }
}

export function createOrderForAccount(data: any){
  return {
    type: constants.BILLING_SAGA_CREATE_ORDER_FOR_ACCOUNT,
    payload: data
  }
}

export function setLink(link: string){
  return {
    type: constants.BILLING_REDUCE_SET_QR,
    payload: link
  }
}

export function showWindow(window: string, isShow: boolean ): types.BillingReduceTypes{
  return {
    type: constants.BILLING_REDUCE_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqBilling(isLoad: boolean): types.BillingReduceTypes{
  return {
    type: constants.BILLING_REDUCE_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}