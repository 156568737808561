import React from 'react';

import { Field, useForm } from 'react-final-form';
import { radioValidator, requiredValidator } from 'components/form/validators';
import { TextAreaField, RadioLikeField, IsOneField } from 'components/form/fields';
import { IQuestion } from 'domain/types/secretShopper.type';

interface IProps {
  disabled?: boolean;
  question: IQuestion;
}

const checkCommentBy = (answers: any[], value: string) => {
  const _filter = answers.filter( (a:any) => a.value === value )
  if(_filter.length) { 
    return _filter[0].comment;
  }

  return false;
}

export const SwitchField: React.FC<IProps> = (props: any) => {
  const { disabled = false, question } = props;
  const form = useForm();

  const rerender = () =>{
    const state = form.getState().values;
    
    switch (question.type) {
      case 'text':
        return (
          <div className="p-col-12 p-p-0 p-d-flex">
            <Field
              validate={requiredValidator}
              name={`questions.${question.id}.question`}
              label={`${question.question}:`}
              // render={TextField}
              render={TextAreaField}
              settings={{
                placeholder: "Если не знаете поставьте прочерк",
                disabled: disabled
              }}
              style={{
                border: 'none',
                width: 'calc(100% - 85px)',
                paddingRight: '0'
              }}
              styleWrapper={{
                width: '100%'
              }}
            />
            <Field
              validate={radioValidator}
              name={`questions.${question.id}.isLike`}
              render={RadioLikeField}
              disabled={disabled}
              style={{
                border: "none",
                padding: "0px",
                maxWidth: "85px",
                paddingTop: "20px"
              }}
            />
          </div>
        );
        case 'isOne':
          const answer = question.variants.split(" #:")
          const comments = question.commentBy.split(" #:")
          const arr = [] as any[]
          
          answer.forEach( (a: any, index: number) => {
            arr.push({ id: index, value: a, comment: comments[index] === 'true' })
          })

          return (
            <>
            <Field
              validate={requiredValidator}
              name={`questions.${question.id}.question`}
              label={`${question.question}:`}
              render={IsOneField}
              disabled={disabled}
              style={{
                border: "none"
              }}
              variants={question.variants}
              commentBy={question.commentBy}
            />
            {checkCommentBy(arr, state?.questions?.[question.id]?.question)
              && <Field
              validate={requiredValidator}
              name={`questions.${question.id}.comment`}
              // render={TextField}
              render={TextAreaField}
              settings={{
                placeholder: "Что Вам не понравилось?",
                disabled: disabled
              }}
            />
            }
            
            </>
          );
      case 'area':
        return (
          <div className="p-col-12 p-p-0 p-d-flex">
            <Field
              validate={requiredValidator}
              name={`questions.${question.id}.question`}
              label={`${question.question}:`}
              render={TextAreaField}
              settings={{
                placeholder: "Если не знаете поставьте прочерк",
                disabled: disabled
              }}
              style={{
                border: 'none',
                width: 'calc(100% - 85px)',
                paddingRight: '0'
              }}
              styleWrapper={{
                width: '100%'
              }}
            />
            <Field
              validate={radioValidator}
              name={`questions.${question.id}.isLike`}
              render={RadioLikeField}
              disabled={disabled}
              style={{
                border: "none",
                padding: "0px",
                maxWidth: "85px",
                paddingTop: "20px"
              }}
            />
          </div>
        );
      default:
        return <></>
    }
  }

  return (
    <>
      {rerender()}
    </>
  );
};


export default SwitchField;