export const USER_CLIENT_SAGA_GET = "USER_CLIENT_SAGA_GET";
export const USER_SAGA_GET_BY_ID = "USER_SAGA_GET_BY_ID";
export const USER_OWNER_SAGA_GET = "USER_OWNER_SAGA_GET";
export const USER_CLIENT_REDUCE_SET = "USER_CLIENT_REDUCE_SET";
export const USER_REDUCE_SET_CURRENT = "USER_REDUCE_SET_CURRENT";
export const USER_OWNER_REDUCE_SET = "USER_OWNER_REDUCE_SET";
export const USERS_IS_SHOW = "USERS_IS_SHOW";

export const USER_SAGA_REGISTRATION = "USER_SAGA_REGISTRATION";
export const USER_SAGA_OWNER_AND_MANAGER = "USER_SAGA_OWNER_AND_MANAGER";
export const USER_SAGA_GET_MANAGER_BY_ID = "USER_SAGA_GET_MANAGER_BY_ID";
export const USER_SAGA_BIND_MANAGER_RESTAURANT = "USER_SAGA_BIND_MANAGER_RESTAURANT";
export const USER_SAGA_UPDATE_BIND_MANAGER_RESTAURANT = "USER_SAGA_UPDATE_BIND_MANAGER_RESTAURANT";

export const USERS_CHECKOUT_REQUEST = "USERS_CHECKOUT_REQUEST";