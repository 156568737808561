import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showWindow } from 'domain/actions/billing.action';

import { Dialog } from "primereact/dialog";
import FirstLayout from "./order.layouts/first.layout";
import QrLayout from "./order.layouts/qr.layout";
import QuestionnaireWindow from "./questionnaire.window";
import SendLinkWindow from "./send.link.window";
import { setSchema } from "domain/actions/secretShopper.action";
import ReceiptLayout from "./order.layouts/receipt.layout";


export const CreateOrderWindows: React.FC = (props: any) => {
  const [ type, setType ] = useState<null | string>(null);
  const { visible } = props;
  const { showWindow, setSchema } = props;

  const handleClose = () => {showWindow('new', false);};

  useEffect( () => {
    if(!visible) {
      setType(null);
      setSchema(null);
    }
  }, [visible] ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog header="Создание счета на оплату" visible={visible} style={{minWidth: '375px', maxWidth: '1024px'}} onHide={handleClose}>
        {type === null && <FirstLayout setType={setType}/>}

        {type === "qr" && (
          <QrLayout setType={setType} />
        )}

        {type === "payment" && (
          <ReceiptLayout setType={setType} />
        )}
      </Dialog>

      <QuestionnaireWindow />
      <SendLinkWindow />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.billing.windows.new
})

export default connect(mapStateToProps, { 
  showWindow, setSchema
})(CreateOrderWindows);