import React, { useState } from "react";
import { connect } from "react-redux";
import { 
  showWindow, 
  setFilters,
  getRestaurants,
  setPageRestaurants,
  copyDishes
} from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { SelectField, TextAreaField } from "components/form/fields";
import ListRestaurantWindows from "../windows/list.restaurant.window";

export const CopyDishesWin: React.FC = (props: any) => {
  const { visible, cities } = props;
  const { showWindow } = props;
  const { setFilters, getRestaurants, setPageRestaurants, copyDishes } = props;
  const [ selected, setSelected ] = useState(null);
  

  const handleClose = () => {
    showWindow('copyDishes', false)
  };
  const handleSelect = () => showWindow('list_restaurants', true);
  const onSelected = (value: any, form: any) => {
    setSelected(value)
    form.change('restaurant', value )
  }; 

  const onSubmit = (values: any) => {
    copyDishes(values.restaurant.id)
  }

  return (
    <Dialog header="Копирование блюд" visible={visible} className="p-col-12 p-p-0 p-md-10 p-lg-8" onHide={handleClose}>
      <h4>Копирование блюд из выбранного заведения</h4>

      <Form
        onSubmit={onSubmit}
        initialData={{}}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, submitting, values, form }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="p-mt-2 p-mb-2">
                <Field
                  name="city" label="Город"
                  render={SelectField}
                  settings={{
                    options: cities,
                    optionLabel: "name",
                    filter: true,
                    showClear: true, 
                    filterBy: "name",
                    placeholder: "Выберите город",
                    emptyFilterMessage: "Совпадений не найдено"
                  }}
                />
              </div> 

              {values.city && (
                <>
                    <div className="p-mt-2 p-mb-2">
                      <div className="p-inputgroup">
                        <div style={{ width: '100%'}}>
                          <Field
                            name="restaurant.name"
                            label="Заведение"
                            render={TextAreaField}
                            settings={{
                              disabled: true,
                            }}
                          />
                        </div>
                        <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                          e.preventDefault();
                          if(values.city) {
                            setFilters([values.city], [], ['active'])
                            setPageRestaurants(1)
                            onSelected(null, form)
                            getRestaurants()
                          };
                          handleSelect();
                        }} />
                      </div>
                    </div>

                </>
              )}
              

              <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
                <Button label="Назад" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" onClick={(e)=>{
                  e.preventDefault();
                }} />
                <Button label="Скопировать" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-success" disabled={submitting} />
              </div>
            </form>

            <ListRestaurantWindows city={values.city ? values.city : null} defaultValue={selected} onSubmit={(value) => onSelected(value, form)} />
          </>
        )}
      />

      
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  cities: state.cities.cities,
  visible: state.restaurants.windows.copyDishes,
})

export default connect(mapStateToProps, { 
  showWindow, setFilters, getRestaurants, setPageRestaurants, copyDishes
})(CopyDishesWin);