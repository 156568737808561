import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { 
  showWindow, 
  createHookah, 
  getCategoriesHookah
} from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { IDishes } from "domain/types/restaurants.type";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField, TextAreaField, TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";

export const AddHookahWin: React.FC = (props: any) => {
  const { visible, categories } = props;
  const { showWindow, createHookah, getCategoriesHookah } = props;
  const [ file, setFile ] = useState(0);
  let refGallery = useRef<any>(null);

  const handleClose = () => showWindow('addHookah', false);
  const onSubmit = (values: IDishes) => {
    // if(!file) return showError("Фотография не загружена!")
    
    createHookah( values, file )
  };

  useEffect(() => getCategoriesHookah(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const dataForm = {
    categoryId: null,
    name: "",
    description: "",
    photo: "",
    price: "",
  }

  return (
    <Dialog header="Создание элемента" visible={visible} className="p-col-12 p-p-0 p-md-10 p-lg-8" onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={dataForm}
        render={({ handleSubmit }) => (
          
          <form onSubmit={handleSubmit}>
            <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
              <div className="p-col-12 p-md-6 p-p-0">
                <div className="p-col-12 p-d-flex p-jc-center">
                  <SingleUploadImage ref={refGallery} label={"Загрузить фотографию"} onSelectFIle={setFile} file={file}/>
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name" label="Наименование:" render={TextField}
                  />
                </div>

              </div>

              <div className="p-col-12 p-md-6 p-p-0">

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="price" label="Цена:" render={TextField}
                    settings={{ placeholder: "Цена за кальян", keyfilter: "int" }}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="categoryId" label="Категория:" render={SelectField}
                    settings={{
                      options: categories,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="description" label="Описание:" render={TextAreaField}
                  />
                </div>

              </div>
            </div>
              
              <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2 p-jc-between">
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Создать" className="p-col-12 p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.addHookah,
  categories: state.restaurants.hookah.categories,
})

export default connect(mapStateToProps, { 
  showWindow, createHookah, getCategoriesHookah
})(AddHookahWin);