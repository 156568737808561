import React, { useEffect, useState } from 'react';

import { useForm } from 'react-final-form';
import { Dialog } from 'primereact/dialog';
import { IQuestion } from 'domain/types/secretShopper.type';
import SwitchField from './switch.field';

interface IProps {
  questions: IQuestion[];
}

export const FarewellContent: React.FC<IProps> = (props: any) => {
  const { questions } = props;
  const [ show, setShow ] = useState(false);
  const [ urlImage, serUrlImage ] = useState('');
  const [ photos, setPhotos ] = useState<string[]>([])
  const [ filters, setFilter ] = useState<IQuestion[]>([]);
  const form = useForm ();

  const handleZoomImg = (fileName: string) => {
    serUrlImage(`/api/admin/files/receipts/${fileName}`);
    setShow(true);
  };

  useEffect(() => {
    const photos = form.getState().values.photos;
    if(photos && photos.length > 0){
      setPhotos(JSON.parse(photos))
    }
  }, [form.getState().values.photos]); // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'farewell')
    
    setFilter(filtered);
  }, [questions])
  
  const classField = "p-col-12 p-md-6 p-xl-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled={true}/>
      </div>
    )
  })

  return (
    <>
      <h3 className="p-m-2">Расчёт прощание: </h3>

      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
        
        {rerender}

        <div className="p-col-12">
          <div
            onClick={()=>handleZoomImg(form.getState().values.receipt)}
            style={{cursor: "zoom-in"}}
          >
            <img src={`/api/admin/files/receipts/${form.getState().values.receipt}`} alt="" width="325px"
              onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
          </div>
        </div>

        {photos.length && (
          <div className="p-col-12 ">
            <h3>Дополнительные фотографии</h3>
            <div className="p-d-flex p-flex-wrap">
              {photos.map( (i:any, index: any) => (
                <div
                  onClick={()=>handleZoomImg(i)}
                  style={{cursor: "zoom-in"}}
                  className=''
                  key={index}
                >
                  <img src={`/api/admin/files/receipts/${i}`} alt="" width="120px"
                    onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
                </div>
              ))}
            </div>
            
          </div>
        )}

      </div>

      <Dialog visible={show} style={{minWidth: '383px'}} onHide={()=>setShow(false)}>
        <img src={urlImage} alt="" onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} />
      </Dialog>
    </>
  );
};

export default FarewellContent;