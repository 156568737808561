import { Chip } from "primereact/chip";
import { IRestaurant } from "domain/types/restaurants.type";
import { isHTTPS } from "utils/regexp";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol;

export const toRestaurantsChips = (restaurants: IRestaurant[]) => {
  const host = hostname === 'localhost' ? 'localhost:3000' : hostname === 'admin.3raza.com' ? "3raza.com" : "demo.3raza.com";
  const url = protocol + "//" + host + "#/main/restaurant/";
  
  const chips = restaurants.map( rest => {
    const src = rest && rest.logo 
      ? `${isHTTPS(rest.logo) 
        ? rest.logo 
        : '/api/files/' + rest?.logo}` 
      : '/api/admin/files/default'

    return (
      <div key={rest.id}>
        <a href={url + rest.id} target="_blank" rel="noopener noreferrer">
          <Chip label={rest.name} image={src} className="p-mr-2 p-mb-2" />
        </a>
      </div>
    )
  } )

  return chips;
}