import { all, fork } from 'redux-saga/effects'

import auth from './auth.saga';
import secretShopper from './secretShopper.saga';
import users from './users.saga';
import restaurants from './restaurants.saga';
import stocks from './stocks.saga';
import coupons from './coupons.saga';
import cities from './cities.saga';
import hashtags from './hashtags.saga';
import norr from './norr.saga';
import notification from './notification.saga';
import billing from './billing.saga';
import settings from './settings.saga';

export default function* rootSaga() {
  yield all(
    [
      fork(auth),
      fork(secretShopper),
      fork(users),
      fork(restaurants),
      fork(stocks),
      fork(coupons),
      fork(cities),
      fork(hashtags),
      fork(norr),
      fork(notification),
      fork(billing),
      fork(settings)
    ])
}
