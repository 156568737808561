import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { ISchema } from 'domain/types/secretShopper.type';
import { showWindowSchema, setSchema, getSchemas } from 'domain/actions/secretShopper.action';

export const QuestionnaireWindow: React.FC<any> = (props: any) => {
  const { 
    visible, 
    schemaQuestionnaires, 
    showWindowSchema, 
    setSchema,
    getSchemas
  } = props;

  const [selected, setSelected] = useState<ISchema | null>(null);
  
  const handleClose = () => showWindowSchema('schemaQuestionnaire', false)
  const handleSelect = () => {
    if(!selected) return; 
    setSchema(selected);
    handleClose();
  }

  useEffect(() => getSchemas(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog
        className="p-col-12 p-md-8 p-lg-6 p-p-0"
        header="Выбор анкеты"
        visible={visible}
        onHide={handleClose}
        modal
        draggable={false}
      >
        <h4 className="p-m-2">Список вариантов анкет</h4>

        <div className="p-col-12 p-d-flex p-flex-wrap ">
          {
            schemaQuestionnaires.map((questionnaire: ISchema) => {
              return (
                <div 
                  key={questionnaire.id} 
                  className="p-col-12 p-md-6 p-xl-4 p-pr-1 p-pb-0 p-pl-0 p-pt-1"
                >
                  <div className="p-field-radiobutton question-style p-p-2">
                    <div className="p-d-flex p-col-12 p-p-0" style={{cursor: 'pointer'}}>
                      <RadioButton 
                        inputId={questionnaire.id} 
                        name="questionnaire" 
                        className="p-mr-2"
                        value={questionnaire} 
                        onChange={(e) => setSelected(e.value)} 
                        checked={selected ? selected.id === questionnaire.id : false}
                      />
                      <label 
                        htmlFor={questionnaire.id} 
                        className="p-pl-2" 
                        style={{cursor: 'pointer', width: 'inherit'}}
                      >{questionnaire.name}</label>
                    </div>
                  </div>
                  
                </div>
              )
            })
          }
          
          <div className="p-col-12 p-p-0">
            <Button onClick={handleSelect} label="Выбрать" className="p-col-12 p-md-5 p-button-outlined p-mt-2" disabled={!selected} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.secretShopper.schemas.windows.schemaQuestionnaire,
  schemaQuestionnaires: state.secretShopper.schemas.data
})

export default connect(mapStateToProps, 
  {
    showWindowSchema,
    setSchema,
    getSchemas
  }
)(QuestionnaireWindow);