import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { groupBy } from 'lodash';
import { IHookah } from 'domain/types/restaurants.type';
import { saveHookah, showWindow } from 'domain/actions/restaurants.action';
import { connect } from 'react-redux';
import CategoryMenu from './category.menu';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dialog } from 'primereact/dialog';
import AddHookahWin from './add.hookah.window';
import EditHookahWin from './edit.hookah.window';

const HookahMenuWin: React.FC = (props: any) => {
  const { current, categories, showWindow, visible } = props;
  const [menu, setMenu] = useState<any>({});
  const [item, setItem] = useState<IHookah | null>(null);

  const handleClose = () => showWindow('hookah', false);
  const handleAdd = (e: any) => {
    e.preventDefault();
    showWindow('addHookah', true)
  }

  useEffect(() =>{
    if(!current) return;
    const group = groupBy(current.hookah, (item) => item.categoryId)
    setMenu(group)
  }, [current, categories])

  

  const renderCategory = Object.keys(menu).map((key) => {
    const category = categories.filter( (category: { id: string; }) => category.id === key)
    return <CategoryMenu key={key} category={category} items={menu[key]} setItem={setItem} />;
  });

  return (
    <Dialog header="Список кальянов" visible={visible} className="p-col-12 p-p-0 p-md-10" onHide={handleClose}>
      <>
        <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
        
        <ScrollPanel style={{ width: '100%', minHeight: '194px' }} className="custombar ">
          <>
          
          <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
            {renderCategory}
          </div>
          </>
        </ScrollPanel>
        
        <AddHookahWin />
        <EditHookahWin item={item} />
      </>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.hookah,
  current: state.restaurants.current.restaurant,
  categories: state.restaurants.hookah.categories
})

export default connect(mapStateToProps, {showWindow, saveHookah})(HookahMenuWin);
