import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { 
  getCategories,
  getKitchens,
  getTagsDishes, 
  getRestaurantById, 
  getRestaurants,
  getManagerByRestaurant, 
  setActiveRestaurant, 
  showWindow,
  showCurrentWin,
  uploadRestaurantsByXls,
  setPageRestaurants,
  setSizePageRestaurants,
  setLike,
  fullnessRestaurant
} from 'domain/actions/restaurants.action';

import {DebounceInput} from 'react-debounce-input';
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import { useMediaQuery } from 'react-responsive'

import { Paginator } from 'primereact/paginator';
import OwnerWindow from './windows/bindOwner.window';
import DeleteWin from './windows/delete.window';
import { getOwners } from "domain/actions/users.action";
import AddWin from "./windows/add.window";
import UpdateWin from "./windows/update.window";
import FiltersWin from "./windows/filters.window";
import HookahMenuWin from "./hookah/hookah.menu.window";
import { ICategory, IRestaurant } from "domain/types/restaurants.type";
import BindManagerDialog from "./windows/bind.manager.window";
import CoordinateDialog from "./windows/coordinate.window";
import ListYoutubeDialog from "./windows/list.youtube.window";
import QRWin from "./windows/qr.window";
import { toStatusRestaurant } from "pages/views/controllers/toStatus";
import { isHTTPS } from "utils/regexp";
import { FileUpload } from "primereact/fileupload";
import CreateOrderWindows from "./windows/create.order.window";
import ListVideoRutubeDialog from "./windows/list.video.rutube.window";

export const toStatusTemplate = (rowData: any) => <span className={`rest-status rest-status-${rowData.status}`}>{toStatusRestaurant(rowData.status)}</span>
export const imageBody = (rowData: any) => {
  const src =`${isHTTPS(rowData.logo) ? rowData.logo : '/api/admin/files/' + rowData.logo}`

  return (
    <div className="p-d-flex p-ai-center">
      <img src={src} alt="" className=""
        width={48} height={48}
        style={{ borderRadius: '50%', objectFit: "contain", boxShadow: "0px 0px 6px 1px #979696" }} 
        onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} 
      />
      
      <div className="p-d-flex p-flex-column p-pl-2">
        <span > <strong>{rowData.name}</strong> (<i>tel:{rowData.telephone}</i>)</span>
        <span > <strong>{rowData.city.name}</strong>. <i>{rowData.address}</i></span>
      </div>
    </div>
  );
}

export const ListRestaurants: React.FC = (props: any) => {
  const { 
    loading, 
    restaurants,
    pagination, 
    getRestaurants, 
    setActiveRestaurant, 
    showWindow, 
    getOwners,
    setPageRestaurants, 
    setSizePageRestaurants,
    setLike,
    like 
  } = props;
  const { 
    getCategories, 
    getKitchens,
    getTagsDishes,
    getManagerByRestaurant, 
    showCurrentWin,
    uploadRestaurantsByXls,
    fullnessRestaurant
  } = props;
  const { count } = pagination;
  const [ file, setSelectFIle ] = useState<any>(null)
  const [ first, setFirst ] = useState(1);
  const [ sizePage, setSizePage ] = useState(10)
  const [ listens, setListen ] = useState("");
  const [ selectedProducts, setSelectedProducts ] = useState<any>(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => { setLike(listens) }, [listens]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { getRestaurants() }, [like]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => getOwners(), [getOwners])
  useEffect(() => getCategories(), []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => getKitchens(), []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => getTagsDishes(), []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSelectedProducts(restaurants.filter((r: IRestaurant) => r.isFullness === true ))
  },[restaurants])

  const onPageChange = (event: any) => {
    setPageRestaurants(event.page + 1);
    setSizePageRestaurants(event.rows);
    setFirst(event.first);
    setSizePage(event.rows);
    handleRefresh();
  }

  const handleAdd = () => showWindow('add', true);
  const handleRefresh = () => getRestaurants();
  const handleUpload = () => uploadRestaurantsByXls(file);
  const handleDelete = (id: string) => showCurrentWin(id, 'delete');
  const handleViewing = (id: string) => showCurrentWin(id, 'update');
  const handleQrCode = (id: string) => showCurrentWin(id, 'qr_restaurant');
  const handleBindOwner = (id: string) => showCurrentWin(id, 'bind_owner');
  const handleBindManager = (id: string) => {
    getManagerByRestaurant(id)
    showCurrentWin(id, 'bind_manager');
  };
  const handleCoordinate = (id: string) => showCurrentWin(id, 'coordinate');
  // const handleYouTube = (id: string) => showCurrentWin(id, 'list_youtube')
  const handleRuTube = (id: string) => showCurrentWin(id, 'list_rutube')
  const handleActive = (id: string) => setActiveRestaurant(id, true)
  const handleDeactivate = (id: string) => setActiveRestaurant(id, false)
  const handleFilters = () => showWindow('filters', true);
  const handleShopperTask = (id: string) => showCurrentWin(id, 'create_task')
  const handleHookah = (id: string) => showCurrentWin(id, 'hookah');
  const handleFullness = (id: string, isFullness: boolean) => { 
    fullnessRestaurant(id, isFullness)
  }

  let menu = (item: any) => [
    {
      label: 'Активировать', 
      icon: 'pi pi-fw pi-check-circle', 
      command:()=> handleActive(item.id),
      disabled: item.status === 'active'
    },
    {
      label: 'Деактивировать', 
      icon: 'pi pi-fw pi-ban', 
      command:()=> handleDeactivate(item.id),
      disabled: item.status === 'notActive'
    },
    {
      label: 'Просмотр заведения', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(item.id),
    },
    {
      label: 'QR-CODE заведения', 
      icon: 'pi pi-fw pi-qrcode', 
      command:()=> handleQrCode(item.id),
    },
    {
      label: '(ТП) Уникальное задание', 
      icon: 'pi pi-fw pi-shopping-cart', 
      command:()=> handleShopperTask(item.id),
    },
    {
      label: 'Координаты', 
      icon: 'pi pi-fw pi-map-marker', 
      command:()=> handleCoordinate(item.id),
    },
    {
      label: 'Добавить видео', 
      icon: 'pi pi-fw pi-youtube', 
      command:()=> handleRuTube(item.id),
    },
    {
      label: 'Список кальянов', 
      icon: 'pi pi-fw pi-circle-on', 
      command:()=> handleHookah(item.id),
      disabled: !item.categories.map( 
          (category: ICategory) => category.name
        ).includes('Кальянная')
    },
    {
      label: 'Назначить владельца', 
      icon: 'pi pi-fw pi-replay', 
      command:()=> handleBindOwner(item.id) 
    },
    {
      label: 'Назначить управляющего', 
      icon: 'pi pi-fw pi-replay', 
      command:()=> handleBindManager(item.id) 
    },
    {
      label: 'Удалить заведение', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> handleDelete(item.id) 
    }
  ]
  
  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  const indexColumn = (rowData: any, index: any) => <div className="p-text-center p-col-12"> {index.rowIndex + 1} </div>  

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 208px)' }}>

        <div className="p-col-12 p-pl-0 p-d-flex p-flex-wrap p-ai-baseline p-jc-between">
          <div className="p-d-flex p-flex-wrap p-py-2">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text p-ml-1"/>
            
            <FileUpload 
              mode="basic" 
              name="xls"
              accept="xlsx/xls" 
              auto
              onSelect={ (e:any) => {
                if(e.files.length) setSelectFIle(e.files[0]);
              }}
              onBeforeUpload={() => {
                handleUpload()
              }}
              chooseLabel={isMobile ? "XLS" : "Загрузить excel"} 
              className="p-button-raised p-button-success p-button-text p-ml-2"
            />
          </div>

          <div className="p-d-flex">
            <span className="p-float-label p-input-icon-left" 
              style={{ width: isMobile ? 'calc(100vw - 76px)' : 'auto' }}
            >
              <i className="pi pi-search" />
              <DebounceInput
                minLength={0}
                debounceTimeout={1000}
                placeholder="Поиск по заведениям"
                className="p-inputtext p-component p-col-12"
                onChange={event => setListen(event.target.value)} />
            </span>
            <Button icon="pi pi-sliders-h" onClick={handleFilters} className="p-button-raised p-button-info p-button-text p-ml-1"/>
          </div>
        </div>

        <div className="card">
        <DataTable 
          value={restaurants} 
          scrollable={!isMobile} 
          scrollHeight={!isMobile ? "calc(100vh - 324px)" : ""} 
          responsiveLayout="stack"
          breakpoint="768px" 
          loading={loading}
          className="table-mobile-rows not-selection-btn"
          selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
          onRowSelect={(e) => handleFullness(e.data.id, true)} onRowUnselect={ (e) => handleFullness(e.data.id, false)}
          selectionMode="checkbox"
        >
          <Column body={indexColumn} style={{ maxWidth: '3rem', padding: '0.2rem' }}></Column>
          <Column header="Заведение" body={imageBody} sortable sortField="name"></Column>
          <Column body={toStatusTemplate} header="Статус" sortable sortField="status"
            style={{ maxWidth: '7rem' }}></Column>
          <Column field="isFullness" header="Заполнено" selectionMode="multiple" style={{ maxWidth: '7rem' }} ></Column>
          <Column body={action} style={{ maxWidth: '5rem', float: "right" }}></Column>

        </DataTable>
        <Paginator first={first} rows={sizePage} totalRecords={count} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange}></Paginator>
        </div>

      </ScrollPanel>

      <AddWin />
      <UpdateWin />
      <OwnerWindow />
      <BindManagerDialog />
      <CoordinateDialog />
      <DeleteWin />
      <FiltersWin />
      <QRWin />
      <HookahMenuWin />
      <ListYoutubeDialog />
      <ListVideoRutubeDialog />
      <CreateOrderWindows />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  restaurants: state.restaurants.restaurants,
  like: state.restaurants.filters.like,
  pagination: state.restaurants.pagination,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { 
  getRestaurants, getRestaurantById, setActiveRestaurant, showWindow, getOwners,
  getCategories, getKitchens, getTagsDishes, showCurrentWin, uploadRestaurantsByXls,
  setPageRestaurants, setSizePageRestaurants, setLike, getManagerByRestaurant,
  fullnessRestaurant
})(ListRestaurants);