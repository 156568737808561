import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/users.type';
import * as actions from "domain/actions/users.action";
import * as constants from "domain/constants/users.constant";
import * as reducers from "domain/reducers/users.reduce";
import * as restaurantActions from "domain/actions/restaurants.action";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getRestaurant } from 'domain/reducers/restaurants.reduce';

const api = new ApiController();
const norr = new NorrController();
const regReplace = /[\s()-]/gm;

export async function getClientsFetch(): Promise<Result<Array<types.IUser>>> {
  return await api.get(`/users/clients`)
}

export async function getOwnersFetch(): Promise<Result<Array<types.IUser>>> {
  return await api.get(`/users/owners`)
}

export async function getUserByIdFetch(id: string): Promise<Result<types.IUser>> {
  return await api.get(`/users/${id}`)
}

export async function registrationFetch(values: any): Promise<Result<types.IUser>> {
  return await api.post(`/users/register`, values)
}

export async function getManagerFetch(id: string): Promise<Result<Array<types.IUser>>> {
  return await api.get(`/manager/${id}`)
}

export async function appointmentOwnerFetch(values: any): Promise<Result<void>> {
  return await api.post(`/manager/connect/owner`, values)
}

export async function appointmentRestaurantFetch(values: any): Promise<Result<void>> {
  return await api.post(`/manager/connect/restaurant`, values)
}

export async function updateAppointmentRestaurantFetch(values: any): Promise<Result<void>> {
  return await api.put(`/manager/bind/${values.id}`, values)
}

export function* getClients(): any{
  yield put(actions.reqUsers(true))

  const response = yield call(getClientsFetch);

  if(response.type === "success"){
    response.value.forEach( (res: any) => {
      res.fio = `${res.surname ? res.surname : ""} ${res.name}`
    } )
    yield put(actions.setClients(response.value))
  }else {
    console.log(response, "error clients")
  }

  yield put(actions.reqUsers(false))
}

export function* getUserById(action: any): any{

  const response = yield call(getUserByIdFetch, action.payload.clientId);
  if(response.type === "success"){
    yield put(actions.setCurrentUser(response.value))
  }else {
    console.log(response, "error clients")
  }

}

export function* getManagerById(action: any): any{

  const response = yield call(getManagerFetch, action.payload.clientId);
  if(response.type === "success"){
    response.value.fio = `${response.value.surname ? response.value.surname : ''} ${response.value.name}`
    
    yield put(actions.setCurrentUser(response.value))
  }else {
    console.log(response, "error clients")
  }

}

export function* getOwners(): any{
  yield put(actions.reqUsers(true))

  const response = yield call(getOwnersFetch);

  if(response.type === "success"){
    response.value?.forEach( (user: any) => {
      user.fio = `${user.surname ? user.surname : ''} ${user.name}`
      user.rest = user.restaurants.map( (rest: any) => rest.name ).join( ", " ).slice(0, 32);
      if(user.rest.length >= 32) user.rest = user.rest + '...'
    })
    yield put(actions.setOwners(response.value))
  }else {
    console.log(response, "error owners")
  }

  yield put(actions.reqUsers(false))
}

export function* registration(action: any): any{
  yield put(actions.reqUsers(true))
  const values = action.payload.values
  
  let params = {
    phone: values.phone.replace(regReplace, ""),
    name: values.name,
    password: values.password,
    checkPassword: values.confirm,
    city: {
      name: values.city.data.city,
      region: values.city.data.region_kladr_id,
    },
    role: values.role,
    comment: values.comment
  };

  const response = yield call(registrationFetch, params);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('register', false))
    yield call(getOwners)
  })

  yield put(actions.reqUsers(false))
}

export function* appointmentOwner(action: any): any{
  yield put(actions.reqUsers(true))
  const values = action.payload.values
  const manager = yield select(reducers.getUser);
  
  const data = {
    ownerId: values.owner.accountId,
    managerId: manager.accountId,
  }
  
  const response = yield call(appointmentOwnerFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('setting_manager', false))
  })

  yield put(actions.reqUsers(false))
}

export function* bindManagerRestaurant(action: any): any{
  const values = action.payload.values
  const restaurant = yield select(getRestaurant);

  const data = {
    managerId: values.accountId,
    restaurantId: restaurant.id
  }
  
  const response = yield call(appointmentRestaurantFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(restaurantActions.showWindow('bind_manager', false))
  })

}

export function* updateBindManagerRestaurant(action: any): any{
  const values = action.payload.values
  const restaurant = yield select(getRestaurant);
  console.log(values)
  const data = {
    id: values.id,
    managerId: values.manager.accountId,
    restaurantId: restaurant.id
  }
  
  const response = yield call(updateAppointmentRestaurantFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(restaurantActions.showWindow('bind_manager', false))
  })

}

export function* watch() {
  yield takeEvery(constants.USER_CLIENT_SAGA_GET, getClients)
  yield takeEvery(constants.USER_OWNER_SAGA_GET, getOwners)
  yield takeEvery(constants.USER_SAGA_GET_BY_ID, getUserById)
  yield takeEvery(constants.USER_SAGA_REGISTRATION, registration)
  yield takeEvery(constants.USER_SAGA_GET_MANAGER_BY_ID, getManagerById)
  yield takeEvery(constants.USER_SAGA_OWNER_AND_MANAGER, appointmentOwner)
  yield takeEvery(constants.USER_SAGA_BIND_MANAGER_RESTAURANT, bindManagerRestaurant)
  yield takeEvery(constants.USER_SAGA_UPDATE_BIND_MANAGER_RESTAURANT, updateBindManagerRestaurant)
}

export default function* sagas() {
  yield fork(watch)
}