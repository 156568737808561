import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { AddressSuggestions } from "react-dadata";

import styles from "../styles.module.css";

type FieldPropsType = FieldRenderProps<string, any>;

export const KladrField = (props: FieldPropsType) => {
  const { meta, icon, input, label, settings } = props;
  const [updateKey, forceUpdate] = useState(1);

  useEffect(() => {
    if (settings.defaultQuery?.length && !input.value) {
      forceUpdate( prev => prev + 1);
    }
  }, [settings, input.value]);

  const removeNonCity = (suggestions: any) => {
    return suggestions.filter((suggestion: any) => suggestion.data.fias_level === "4");
  };

  return (
    <React.Fragment>
      <div
        className={`${styles.input} ${
          meta.error && meta.touched && styles.input_error
        }`}
        key={updateKey}
      >
        <label>{label}</label>
        <div className={styles.inputWrapper}>
          <AddressSuggestions
            {...input}
            {...settings}
            inputProps={{ 
              placeholder: settings.placeholder ? settings.placeholder : "Поиск только городов",
              autoComplete: "new-password"
            }}
            token={process.env.REACT_APP_KLADR_KEY}
            onSuggestionsFetch={removeNonCity}
          />
          {icon && <i className={icon}></i>}
        </div>
        {meta.error && meta.touched && (
          <small className={styles.error}>{meta.error}</small>
        )}
      </div>
    </React.Fragment>
  );
}

