import axios from "axios";
import { Result } from "domain/types/static.type";
import { AuthController } from "./Auth.controller";

class ApiController {
  private Auth: AuthController;
  
  constructor() {
    this.Auth = new AuthController();
  }

  getBaseUrl(): any {
    const prefix = "/api/admin"

    return prefix;
  }  

  private getHeaders(params?: any) {
    let token = this.Auth.getAccessTokenStorage();

    let headers = {
      timeout: 300000,
      headers: {
        "Content-Type": "application/json",
        "Authorization": ""
      },
      params: params,
      baseURL: this.getBaseUrl()
    };
  
    if(token) headers.headers.Authorization = `Bearer ${token}`;

    return headers;
  }
  private async isErrorAuth(err: any) {
    console.log(err)
    if(err.response.status === 422) {
      this.Auth.clearTokens();
      window.location.reload();
    }

    if(err.response.status === 401) {
      try {
        await this.Auth.refreshAccessToken(this.getHeaders())
      } catch (error) { 
        return false;
      }
      return true;
    };
    return false;
  }

  async get(url: string, params?: any): Promise<Result<any>>{
    try{
      const response = await axios.get(url, this.getHeaders(params))
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message

      return isRefresh ? await this.get(url, params) : { type: 'error', error }
    }
  }

  async post(url: string, body: object): Promise<any>{
    try{
      const response = await axios.post(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message

      return isRefresh ? await this.post(url, body) : { type: 'error', error }
    }
  }

  async put(url: string, body: object): Promise<any>{
    try{
      const response = await axios.put(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message

      return isRefresh ? await this.put(url, body) : { type: 'error', error }
    }
  }

  async patch(url: string, body: object): Promise<any>{
    try{
      const response = await axios.patch(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message

      return isRefresh ? await this.patch(url, body) : { type: 'error', error }
    }
  }

  async delete(url: string): Promise<any>{
    try{
      const response = await axios.delete(url, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message

      return isRefresh ? await this.delete(url) : { type: 'error', error }
    }
  }
}

export default ApiController;