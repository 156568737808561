import axios from "axios";
import qs from "qs";
import { AuthController } from "./Auth.controller";


const Auth = new AuthController();

export const instanceBilling = axios.create({
  baseURL: '/api/billing',
  paramsSerializer,
});

export const instanceBackend = axios.create({
  baseURL: '/api',
  paramsSerializer,
});

instanceBilling.interceptors.request.use(requestHandler);
instanceBilling.interceptors.response.use(responseHandler, errorHandler);

instanceBackend.interceptors.request.use(requestHandler);
instanceBackend.interceptors.response.use(responseHandler, errorHandler);

function paramsSerializer(params: any) {
  return qs.stringify(params, { arrayFormat: "repeat" });
}

function requestHandler(request: any, refresh?: boolean) {
  let token = Auth.getAccessTokenStorage();

  request.headers = {
    'authorization': `Bearer ${token}`,
  };

  return request;
}

function responseHandler(response: any) {
  if (response.data && response.data.message) {
    console.log(response.data)
  }

  return { response };
}

async function errorHandler(error: any) {
  const {
    response: { status },
  } = error;
// console.log(error.response)
//   const message =
//     error.response &&
//     error.response.data &&
//     (error.response.data.message || error.response.data.description);

  if(status === 422) {
   
    return { error }
  }
  if (status === 401) {
    try {
      const config = error.response.config
      await Auth.refreshAccessToken()

      const response = await axios(requestHandler(config))
      return { response }
    } catch (error) { 
      return { error }
    }
  } else {
    return { error }
  }
}