import React from "react";

import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { ICategory } from "domain/types/restaurants.type";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextAreaField, TextField } from "components/form/fields";

interface IProps {
  initialValues: ICategory;
  handleClose: () => void;
  onSubmit: (values: ICategory) => void;
}

export const FormCategory: React.FC<IProps> = (props: IProps) => {
  const { initialValues, handleClose, onSubmit } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
          
        <form onSubmit={handleSubmit}>
          <div className="p-col-12">
            <Field
              validate={composeValidators(requiredValidator)}
              name="name" label="Наименование:" render={TextField}
              settings={{ placeholder: "Дорогие кальяны...", }}
            />
          </div>

          <div className="p-col-12">
            <Field
              validate={composeValidators(requiredValidator)}
              name="description" label="Описание:" render={TextAreaField}
            />
          </div>
            
          <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2 p-jc-between">
            <div className="p-col-12 p-md-6">
              <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
            </div>
            <div className="p-col-12 p-md-6">
              <Button label="Применить" className="p-col-12 p-button-raised p-button-success "/>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default FormCategory;