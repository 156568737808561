import { combineReducers } from 'redux'
import * as constants from "domain/constants/settings.constant";
import * as types from "domain/types/settings.type";
import { IStatusResponse } from 'domain/types/static.type';

const initialState = {
  reviews: [],
  windows: { 
    review_update: false,
    review_reject: false
  },
  request: {
    loading: false,
    error: null
  }
};

export function reviews(state = initialState.reviews, action: types.SettingsReduceTypes): any {
  switch (action.type) {
    case constants.SETTINGS_REDUCE_SET_REVIEWS:
      return action.payload.reviews;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.SettingsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.SETTINGS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.SettingsReduceTypes): any {
  switch (action.type) {
    case constants.SETTINGS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function getReviews(state: { settings: { reviews: types.IReview[] } }) {
  return state.settings.reviews
}

export default combineReducers({
  reviews,
  request,
  windows
})