export const STOCKS_SAGA_GET = "STOCKS_SAGA_GET";
export const STOCKS_SAGA_GET_BY_ID = "STOCKS_SAGA_GET_BY_ID";
export const STOCKS_SAGA_POST = "STOCKS_SAGA_POST";
export const STOCKS_SAGA_PUT = "STOCKS_SAGA_PUT";
export const STOCKS_SAGA_DELETE = "STOCKS_SAGA_DELETE";
export const STOCKS_SAGA_CURRENT_IS_SHOW_WINDOW = "STOCKS_SAGA_CURRENT_IS_SHOW_WINDOW";

export const STOCKS_REDUCE_SET = "STOCKS_REDUCE_SET";
export const STOCKS_REDUCE_SET_CURRENT = "STOCKS_REDUCE_SET_CURRENT";
export const STOCK_REDUCE_SET_FILTERS = "STOCK_REDUCE_SET_FILTERS";


export const STOCKS_CHECKOUT_REQUEST = "STOCKS_CHECKOUT_REQUEST";
export const STOCKS_IS_SHOW = "STOCKS_IS_SHOW";
export const STOCKS_SAGA_SHOW_ERROR = "STOCKS_SAGA_SHOW_ERROR";