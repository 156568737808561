import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/coupons.type';
import * as actions from "domain/actions/coupons.action";
import * as constants from "domain/constants/coupons.constant";
import * as reducers from "domain/reducers/coupons.reduce";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { ICity } from 'domain/types/city.type';

const api = new ApiController();
const norr = new NorrController();

interface IFilters {
  cities: ICity[];
  state: string;
};

export async function getCouponsFetch( filters: IFilters ): Promise<Result<types.ICoupon[]>> {
  console.log(filters)
  return await api.get(`/restaurants_coupons`)
}

export async function getCouponByIdFetch(id: string): Promise<Result<types.ICoupon>> {
  return await api.get(`/restaurants_coupons/${id}`)
}

export async function postCouponFetch(data: types.IPostCoupon): Promise<Result<any>> {
  return await api.post(`/restaurants_coupons`, data)
}

export async function putCouponFetch(couponId: string, data: types.IPutCoupon): Promise<Result<any>> {
  return await api.put(`/restaurants_coupons/${couponId}`, data)
}

export async function uploadFileFetch(file: File): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/restaurants_coupons/upload/file`, fd)
}

export async function deleteCouponFetch(couponId: string): Promise<Result<any>> {
  return await api.delete(`/restaurants_coupons/${couponId}`)
}

export function* getCoupons(): any{
  yield put(actions.reqCoupons(true))

  const filterReduce = yield select(reducers.getFiltersCoupons);

  const filters = {
    cities: filterReduce.cities.map( (city: ICity) => city.id ),
    state: filterReduce.state,
    like: ""
  }

  const response = yield call(getCouponsFetch, filters);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCoupons(response.value))
  })

  yield put(actions.reqCoupons(false))
}

export function* getCouponById(action: any): any{
  yield put(actions.reqCoupons(true))

  const response = yield call(getCouponByIdFetch, action.payload.stockId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrent(response.value))
  })
  

  yield put(actions.reqCoupons(false))
}

export function* deleteCoupon(action: any): any{
  const stockId = action.payload.coupon.id;

  const response = yield call(deleteCouponFetch, stockId);
  yield call(norr.processing, response, function *(){
    yield call(getCoupons)
  }, "Купон удален")
}

export function* showCurrentWin(action: any): any{
  const stockId = action.payload.stockId;
  const window = action.payload.typeWindow

  const response = yield call(getCouponByIdFetch, stockId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrent(response.value))
    yield put(actions.showWindow(window, true))
  })
}

export function* postCoupon(action: any): any{
  let data = action.payload.coupon;
  console.log('asdas')
  const photo = data.urlPhoto;

  let urlPhoto = "";
  const uploadLogo = yield call(uploadFileFetch, photo)
  const resultLogo = yield call(norr.processing, uploadLogo, function(){
    urlPhoto = uploadLogo.value
  })

  if(!resultLogo) return ;

  data.urlPhoto = urlPhoto;

  const response = yield call(postCouponFetch, data)
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('add', false))
    yield call(getCoupons)
  },  "Купон создан")
  
}

export function* putCoupon(action: any): any{
  const data = action.payload.data;
  const photo = data.photo;

  if(photo){
    let urlPhoto = "";
    const uploadLogo = yield call(uploadFileFetch, photo)
    const resultLogo = yield call(norr.processing, uploadLogo, function(){
      urlPhoto = uploadLogo.value
    })
    
    if(!resultLogo) return ;

    data.urlPhoto = urlPhoto;
  }

  const response = yield call(putCouponFetch, data.restaurantId, data)
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('update', false))
    yield call(getCoupons)
  },  "Купон изменен")

}

export function* watch() {
  yield takeEvery(constants.COUPONS_SAGA_GET, getCoupons)
  yield takeEvery(constants.COUPONS_SAGA_GET_BY_ID, getCouponById)
  yield takeEvery(constants.COUPONS_SAGA_CURRENT_IS_SHOW_WINDOW, showCurrentWin)
  yield takeEvery(constants.COUPONS_SAGA_POST, postCoupon)
  yield takeEvery(constants.COUPONS_SAGA_PUT, putCoupon)
  yield takeEvery(constants.COUPONS_SAGA_DELETE, deleteCoupon)
}

export default function* sagas() {
  yield fork(watch)
}
