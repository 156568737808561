import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClients, showWindow } from 'domain/actions/users.action';

import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { toDate } from "utils/toDate.util";

import ClientWindow from './windows/user.window';
import ActionsColumn from "pages/views/components/table/action.column";
import { toFioTemplate } from "./components/fio";

export const Client: React.FC = (props: any) => {
  const { loading, clients, getClients, showWindow } = props;
  const [ currentId, setCurrentId ] = useState<string | null>(null);

  useEffect(() => getClients(), [getClients])

  const handleRefresh = () => getClients();
  const handleViewing = (id: string) => {
    setCurrentId(id)
    showWindow('views', true);
  };

  let menu = (item: { accountId: string; role: string }) => {
    const menuItems = [
      {
        label: 'Просмотр', 
        icon: 'pi pi-fw pi-pencil', 
        command:()=> handleViewing(item.accountId)
      }
    ];
    return menuItems;
  }

  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />
    
  

  return (
    <>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
        <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
          <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
        </div>
        </div>

        <div className="card">
        <DataTable value={clients} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column field="fio" header="ФИО" body={(rowData)=>toFioTemplate(rowData)} 
            sortable sortField="fio"
          ></Column>
          <Column field="visitAt" header="Последние посещение" 
            sortable sortField="visitAt" style={{ maxWidth: '12rem' }}
            body={(rowData)=>toDate(rowData.visitAt)}
          ></Column>
          <Column body={action} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
        </div>

      </ScrollPanel>

      <ClientWindow currentId={currentId} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  clients: state.users.clients,
  loading: state.users.request.loading
})

export default connect(mapStateToProps, { getClients, showWindow })(Client);