import React, { useEffect } from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { getRestaurantCategories } from "domain/actions/restaurants.action";

export const MainRestaurants: React.FC = (props: any) => {
  const { getRestaurantCategories } = props;

  useEffect(() => getRestaurantCategories(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Заведения</h1>
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default connect(null, {getRestaurantCategories})(MainRestaurants);