import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { showWindow, postYoutubeVideo } from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";
import { showError } from "domain/actions/norr.action";

export const ListYoutubeDialog: React.FC = (props: any) => {
  const [file, setFile] = useState(0);
  const { visible, current } = props;
  const { showWindow, showError, postYoutubeVideo } = props;
  let refImage = useRef<any>(null);
  
  const onSubmit = (values: any) => {
    if(!file) return showError("Фотография не загружена!")

    values.file = file;

    postYoutubeVideo(current.id, values)
  }
  const handleClose = () => showWindow('list_youtube', false);

  return (
    <>
      <Dialog header="Добавление видео Youtube" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>
        
        <Form
          onSubmit={onSubmit}
          initialValues={null}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='p-mt-3 p-mb-2'>
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="title"
                  label="Описание"
                  render={TextField}
                  settings={{
                    placeholder: "Краткое описание видео",
                  }}
                />
              </div>

              <div className="p-col-12">
                <SingleUploadImage 
                  ref={refImage}
                  label={"Загрузить фотографию"}
                  onSelectFIle={setFile} file={file}
                />
              </div>

              <div className='p-mt-3 p-mb-2'>
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="videoId"
                  label="Идентификатор видео с Youtube"
                  render={TextField}
                  settings={{
                    placeholder: "ol8hWeqKUjU",
                  }}
                />
              </div>
              
              <div className="p-mt-1 p-d-flex p-jc-end">
                <Button label="Применить" />
              </div>
            </form>
          )}
        /> 

      </Dialog>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  visible: state.restaurants.windows.list_youtube
})

export default connect(mapStateToProps, { showWindow, showError, postYoutubeVideo })(ListYoutubeDialog);