export function toStatusRestaurant(status: string): string {
  switch (status) {
    case 'active':
      return 'Активно';
    case 'notActive':
      return 'Не активно';
    default:
      return status
  }
}

export function toStatusStock(status: string): string {
  switch (status) {
    case 'new':
      return 'Новый';
    case 'active':
      return 'Активно';
    case 'deactivate':
      return 'Деактивировано';
    case 'complete':
      return 'Выполнено';
    default:
      return status
  }
}

export function toStatus(status: string): string {
  switch (status) {
    case 'create':
      return 'Свободно';
    case 'raffle':
      return 'Разыгрывается';
    case 'active':
      return 'Активна';
    case 'verification':
      return 'На модерации';
    case 'not_verified':
      return 'Проверка не пройдена';
    case 'verified':
      return 'Проверен, ожидание получения счета';
    case 'payment':
      return 'Подтвердить оплату';
    case 'complete':
      return 'Выполнена';
    case 'expired':
      return 'Просрочено';
    default:
      return status
  }
}

export function toStatusTask(value: string){
  
  switch (value) {
    case 'new':
      return 'Свободно';
    case 'start':
        return 'Идёт набор';
    case 'active':
      return 'В процессе выполнения';
    case 'verification':
      return 'На проверке'
    case 'verified':
      return 'Проверено'
    case 'not_verified':
      return 'Повторно сдать задание'
    case 'reject':
      return 'Отменена'
    case 'confirm':
      return 'Завершена';
    case 'payout':
      return 'Ожидает выплаты';
    default:
      return 'не указано';
  }
}

export function toStatusRaffle(value: string){
  switch (value) {
    case 'active':
      return 'Исполнитель';
    case 'new':
      return 'Участие';
    case 'winner':
        return 'Победитель';
    case 'confirm':
      return 'Выполнил';
    case 'lose':
      return 'Проигравший';
    case 'frozen':
      return 'Заморожен'
    default:
      return 'не указано';
  }
}

export function toStatusOrder(value: string){
  switch (value) {
    case 'new':
      return 'Ожидает оплаты';
    case 'reject':
      return 'Отменена'
    case 'confirm':
      return 'Оплачено';
    default:
      return 'не указано';
  }
}

export function toTypeOrder(value: string){
  switch (value) {
    case 'pay_acc':
      return 'Расчетный счет';
    case 'qr':
      return 'СБП'
    case 'sbp':
      return 'СБП';
    default:
      return 'не указано';
  }
}