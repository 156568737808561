import React from "react";
import { connect } from "react-redux";

import Tasks from "./tasks";
import { TabPanel, TabView } from "primereact/tabview";
import { getSecretShopperTasks, getSecretShopperTasksArchive } from "domain/actions/secretShopper.action";

export const SecretShopperTasks: React.FC = (props: any) => {
  const { getSecretShopperTasks, getSecretShopperTasksArchive } = props;

  return (
    <div className="content">
      <TabView>
        <TabPanel header="Задания">
          <Tasks getSecretShopperTasks={getSecretShopperTasks} />
        </TabPanel>
        <TabPanel header="Архив">
          <Tasks getSecretShopperTasks={getSecretShopperTasksArchive} archive/>
        </TabPanel>
      </TabView>
    </div>
  );
}


export default connect(null, { getSecretShopperTasks, getSecretShopperTasksArchive } )(SecretShopperTasks);