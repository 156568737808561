import React, { useState } from "react";

import { TabPanel, TabView } from "primereact/tabview";
import NationalityKitchen from "../kitchen/nationality";
import CategoryDishes from "../kitchen/category";
import CategoryHookah from "../hookah";
import AddCategoryWin from "../kitchen/add.category.window";
import AddKitchenWin from "../kitchen/add.kitchen.window";


export const CategoriesPage: React.FC = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Категории меню">
          <NationalityKitchen />
        </TabPanel>
        <TabPanel header="Категории блюд">
          <CategoryDishes />
        </TabPanel>
        <TabPanel header="Кальяны">
          <CategoryHookah />
        </TabPanel>
      </TabView>

      <AddCategoryWin />
      <AddKitchenWin />
    </>
  );
}

export default CategoriesPage;