import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setPageRestaurants, showWindow } from 'domain/actions/restaurants.action';

import { Field, useForm } from "react-final-form";
import { aroundQuantityValidator, composeValidators, requiredValidator } from "components/form/validators";
import { DateField, SelectField, TextAreaField, TextField } from "components/form/fields";
import { Button } from "primereact/button";
import { getCities } from "domain/actions/cities.action";
import { getRestaurants, setFilters } from "domain/actions/restaurants.action";
import SingleUploadImage from "components/upload/single.upload";
import ListRestaurantWindows from "../list.restaurant.window";


export const AddCoupon: React.FC<any> = (props: any) => {
  const [ selected, setSelected ] = useState(null);
  const { cities } = props;
  const { handleSubmit, submitting, file, setFile } = props;
  const { handleClose } = props;
  const { setFilters, getRestaurants, getCities, showWindow, setPageRestaurants } = props;
  let refPhoto = useRef<any>(null);

  const form = useForm ();
  const values = form.getState().values;

  useEffect(() => {
    if(values.city) {
      setFilters([values.city], [], ['active'])
      setPageRestaurants(1)
      onSelected(null)
      getRestaurants()
    };
  }, [values.city, getRestaurants, setFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getCities(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = () => showWindow('list_restaurants', true);
  const onSelected = (value: any) => {
    setSelected(value)
    form.change('restaurant', value )
  }; 

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="p-mt-2 p-mb-2">
          <Field
            name="city" label="Город"
            render={SelectField}
            settings={{
              options: cities,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите город",
              emptyFilterMessage: "Совпадений не найдено"
            }}
          />
        </div> 

        {values.city && (
          <>

            <div className="p-mt-2 p-mb-2">
              <div className="p-inputgroup">
                <div style={{ width: '100%'}}>
                  <Field
                    name="restaurant.name"
                    label="Заведение"
                    render={TextAreaField}
                    settings={{
                      disabled: true,
                    }}
                  />
                </div>
                <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                  e.preventDefault();
                  handleSelect();
                }} />
              </div>
            </div>

            <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
              <div className='p-mt-2 p-mb-2'>
                <SingleUploadImage ref={refPhoto} label={"Загрузить фото буклета"} onSelectFIle={setFile} file={file} />
              </div>

              <div className='p-mt-2 p-mb-2 p-col-12 p-py-0 p-px-0 p-px-md-2' style={{maxWidth: '430px'}}>
                <div className='p-mt-0 p-mb-1 p-col-12 p-py-0 p-px-0 p-px-md-2'>
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name"
                    label="Название"
                    render={TextField}
                    settings={{
                      placeholder: "Название акции",
                    }}
                  />
                </div>

                <div className='p-mt-1 p-mb-1 p-col-12 p-py-0 p-px-0 p-px-md-2'>
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="description"
                    label="Описание"
                    render={TextAreaField}
                    settings={{
                      placeholder: "Краткое описание условий акции",
                    }}
                  />
                </div>
              </div>

              <div className='p-col-12 p-md-6 p-px-md-2'>
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="from"
                  label="От"
                  render={DateField}
                />
              </div>

              <div className="p-col-12 p-px-0 p-md-6 ">
              <Field
                  validate={composeValidators(requiredValidator)}
                  name="to"
                  label="До"
                  render={DateField}
                />
              </div>
              
              <div className='p-col-12 p-md-3 p-px-0 p-px-md-2'>
                <Field
                  validate={composeValidators(requiredValidator, aroundQuantityValidator(1, 25))}
                  name="quantity"
                  label="Кол-во акций"
                  render={TextField}
                  settings={{
                    placeholder: "25",
                    keyfilter: 'int',
                  }}
                />
              </div>

              <div className="p-col-12 p-px-0 p-md-9">
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="code"
                  label="Кодовое слово:"
                  render={TextField}
                  settings={{
                    placeholder: "Код требуется для закрытия акции"
                  }}
                />
              </div>

            </div>

          </>
        )}

        <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
          <Button 
            label="Отмена" 
            className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" 
            onClick={(e)=>{
              e.preventDefault();
              handleClose()
          }} />
          <Button label="Создать" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-success" disabled={submitting} />
        </div>
      </form>

      <ListRestaurantWindows city={values.city ? values.city : null} defaultValue={selected} onSubmit={onSelected} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.coupons.windows.add,
  cities: state.cities.cities,
  restaurants: state.restaurants.restaurants,
})

export default connect(mapStateToProps, { 
  showWindow, getCities, setFilters, getRestaurants, setPageRestaurants
})(AddCoupon);