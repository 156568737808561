import React from 'react';
import { connect } from 'react-redux';
import { showWindow } from 'domain/actions/restaurants.action';

import { ConfirmDialog } from 'primereact/confirmdialog';

interface IProps {
  onAccess: () => void;
}

const WarningActiveCouponWin: React.FC<IProps> = (props: any) => {
  const { show, showWindow, onAccess } = props;

  const accept = () => onAccess()
  const reject = () => { console.log("close()") }

  const close = () => showWindow('warningActiveTask', false)

  return <ConfirmDialog visible={show} onHide={close} 
      message={`
        В данном заведении есть активные задания "Тайного покупателя" 
        кэшбэк который зависит напрямую от среднего чека, сменив его, вы измените сумму выплаты победителю. 
        Все равно продолжить операцию?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
      style={{maxWidth: '420px'}}
    />
}

const mapStateToProps = (state: any) => ({
  show: state.restaurants.windows.warningActiveTask,
})

export default connect(mapStateToProps, {showWindow})(WarningActiveCouponWin)