import React, { useEffect, useState } from 'react';

import SwitchField from './switch.field';
import { IQuestion } from 'domain/types/secretShopper.type';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const StandardContent: React.FC<IProps> = (props: any) => {
  const { disabled = false, questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'standard')
    
    setFilter(filtered);
  }, [questions])

  const classField = "p-col-12 p-md-6 p-xl-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled={disabled} />
      </div>
    )
  })

  return (
    
    <>
      {!!filters.length && (
        <>
          <h3 className="p-m-2">Соответствие стандартам: </h3>
    
          <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
            {rerender}
          </div>
        </>
      )}
    </>
  );
};

export default StandardContent;