import React from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from 'react-pro-sidebar';

import styles from './style.module.css';

interface ISidebarProps{
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar: (event: React.MouseEvent<HTMLElement>) => void;
}

export const SidebarComponent: React.FC<ISidebarProps> = (props: any) => {
  const { collapsed, handleToggleSidebar, toggled } = props;


  return (
    <div className={`${styles.sidebar}`}>
      <ProSidebar 
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem icon={<i className="pi pi-chart-line"></i>}>
              <Link  to={"/panel/analytics"}>Аналитика</Link>
            </MenuItem>

            <MenuItem icon={<i className="pi pi-user"></i>}>
              <Link  to={"/panel/users"}>Пользователи</Link>
            </MenuItem>

            <SubMenu title="Заведения" icon={<i className="pi pi-briefcase"></i>}>
              <MenuItem> <Link  to={"/panel/restaurants/all"}>Список заведений</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/restaurants/stocks"}>Акции(Купоны)</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/restaurants/bid_my"}>Заявки "Моё заведение"</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/restaurants/bid_helps"}>Заявки "Помощь в создании"</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/restaurants/menu"}>Настройка категорий</Link> </MenuItem>
            </SubMenu>

            <SubMenu title="Тайный покупатель" icon={<i className="pi pi-users"></i>}>
              <MenuItem> <Link  to={"/panel/secret_shopper/tasks"}>Задания</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/secret_shopper/questionnaires"}>Анкеты</Link> </MenuItem>
            </SubMenu>

            <SubMenu title="Биллинг" icon={<i className="pi pi-dollar"></i>}>
              <MenuItem> <Link  to={"/panel/billing/orders"}>Счета на оплату</Link> </MenuItem>
            </SubMenu>

            <SubMenu title="Уведомления" icon={<i className="pi pi-send"></i>}>
              <MenuItem> <Link  to={"/panel/notification/subscriptions"}>Подписки к push-уведомлениям</Link> </MenuItem>
              <MenuItem> <Link  to={"/panel/notification/email"}>E-mail - рассылка</Link> </MenuItem>
            </SubMenu>

            <SubMenu title="Настройки" icon={<i className="pi pi-cog"></i>}>
              <MenuItem > <Link to={"/panel/settings/reviews"}>Отзывы о путеводителе</Link> </MenuItem>
              <MenuItem > <Link to={"/panel/settings/main"}>Настройки</Link> </MenuItem>
            </SubMenu>

          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};