import React from 'react';
import { connect } from 'react-redux';
import { showWindow } from 'domain/actions/stocks.action';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { IStock } from 'domain/types/stocks.type';

interface IProps {
  stock: IStock | null;
  handleDelete: (stock: IStock) => void;
}

const DeleteStockWin: React.FC<IProps> = (props: any) => {
  const { stock, show, showWindow, handleDelete } = props;

  const accept = () => handleDelete(stock)
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('delete', false)} 
      message={`Вы действительно хотите удалить Акцию?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.stocks.windows.delete,
})

export default connect(mapStateToProps, {showWindow})(DeleteStockWin)